import {apiUrl} from './../constants'

export const createProject = (data) => {
    return fetch(`${apiUrl}/create-project`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getUserProject = (userId) => {
    return fetch(`${apiUrl}/get-user-project/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const deleteProject = (projectId) => {
    return fetch(`${apiUrl}/delete-project/${projectId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const updateProject = (projectId, updatedData) => {
    return fetch(`${apiUrl}/update-project/${projectId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const fetchUsers = (data) => {
    return fetch(`${apiUrl}/fetch-users/${data}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getUsersByTechnologies = (techArray) => {
    return fetch(`${apiUrl}/fetch-users-by-tech-for-project?techArray=${techArray}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        // body: JSON.stringify(techArray)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const fetchRankedUsers = () => {
    return fetch(`${apiUrl}/fetch-ranked-users/`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const joinTeamRequest = (data) => {
    console.log(data)
    return fetch(`${apiUrl}/send-requests-team`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const deSelectRequestedMember = (data) => {
    return fetch(`${apiUrl}/de-select-requested-member`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const addTaskToRequirements = (data) => {
    return fetch(`${apiUrl}/update-tasks-to-requirements`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const removeTeamMember = (data) => {
    return fetch(`${apiUrl}/remove-team-member`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getSingleProject = ({projectId, gitId}) => {
    return fetch(`${apiUrl}/get-single-project/${projectId}/${gitId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log(err.json())
        return err.json()
    })
}

export const getSingleProjectForTeam = (projectId) => {
    return fetch(`${apiUrl}/get-single-project-for-team/${projectId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log(err.json())
        return err.json()
    })
}

export const acceptTeamRequest = (data) => {
    return fetch(`${apiUrl}/accept-team-request`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const rejectTeamRequest = (data) => {
    return fetch(`${apiUrl}/reject-team-request`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const submitCoHost = (data) => {
    return fetch(`${apiUrl}/submit-co-host`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getPublicProject = () => {
    return fetch(`${apiUrl}/get-public-projects`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const fetchRecentChats = (projectId) => {
    return fetch(`${apiUrl}/chatrooms/${projectId}/messages?limit=30`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
};

export const updateGitAccess = (data) => {
    console.log(data)
    return fetch(`${apiUrl}/update-git-user-control/${data.projectId}/${data.gitAccess}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return(err.json())
    })
}

export const updateRepoIntegration = (data) => {
    // console.log(data)
    return fetch(`${apiUrl}/update-repo-integrate-git/${data}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return(err.json())
    })
}

export const assignGitRepoToProject = (data) => {
    return fetch(`${apiUrl}/update-git-repo/${data.projectId}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return(err.json())
    })
}

export const getProjectCommits = (data) => {
    return fetch(`${apiUrl}/get-project-repo-commits/${data.projectId}/${data.gitId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}