import AuthNavbar from "../AuthNavbar"
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthStore } from "../../store/store";
import Autocomplete from '@mui/material/Autocomplete';
import { Collapse, setRef } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { createProject, getUserProject, deleteProject, updateProject } from "../../actions/projectActions";
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CssTextField = styled(TextField, Autocomplete)({
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
        },
    },
    '& .MuiChip-label': {
        color: 'white'
    },
    '& .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiChip-deleteIcon .MuiChip-deleteIconMedium .MuiChip-deleteIconColorDefault .MuiChip-deleteIconOutlinedColorDefault': {
        borderColor: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: "black"
    }
});

const ManageProjects = () => {

    const data = useAuthStore(state => state.user)

    const success = useLocation()?.state || {}
    const updateData = useLocation()?.state || {};
    const { update, projectIdFromDash, projectTitleFromDash, projectDescriptionFromDash, projectTechFromDash, projectCurrentReqFromDash, isPrivateFromDash, projectBudgetAmountFromDash, projectCurrencyFromDash, projectIsFlexibleFromDash, projectBudgetDetailesFromDash } = updateData;

    // console.log(isPrivateFromDash)

    const [displayMessage, setDisplayMessage] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setDisplayMessage(false)
        }, 5000);
    }, [])

    const [newProjectForm, setNewProjectForm] = useState({
        projectId: projectIdFromDash || '',
        projectTechValue: '',
        projectHost: data.user._id,
        projectCoHost: '',
        isPrivate: isPrivateFromDash || false,
        projectTitle: projectTitleFromDash || '',
        projectDescription: projectDescriptionFromDash || '',
        budgetAmount: projectBudgetAmountFromDash || '',
        currency: projectCurrencyFromDash || '',
        isFlexible: projectIsFlexibleFromDash || '',
        budgetDetails: projectBudgetDetailesFromDash || ""
    })

    const [refresh, setRefresh] = useState(false)
    const [updateFlag, setUpdateFlag] = useState(update || false)

    useEffect(() => {
        getProject()
    }, [refresh])

    const [values, setValues] = useState({
        error: false,
        errorMessage: ''
    })

    const [addedProjects, setAddedProjects] = useState([])

    const [displayProjectTech, setDisplayProjectTech] = useState(update || false)
    const [displayform2, setDisplayForm2] = useState(false)
    const [displayProject, setDisplayProject] = useState(!update)

    const [projectTech, setProjectTech] = useState(projectTechFromDash || [])

    const [projectCurrentReq, setProjectCurrentReq] = useState(projectCurrentReqFromDash || [])

    const [projectReqData, setProjectReqData] = useState({
        title: '',
        description: ''
    })

    const { error, errorMessage } = values
    const { projectId, projectHost, projectTechValue, projectTitle, isPrivate, projectDescription, budgetAmount, currency, isFlexible, budgetDetails } = newProjectForm
    const { title, description } = projectReqData

    const handleChange = (name) => (event) => {
        if (name === 'projectTechValue') {
            setDisplayProjectTech(true);
        } else {
            setDisplayProjectTech(false);
        }

        let value = event.target.value;
        if (name === 'isPrivate' || name === 'isFlexible') {
            value = value === 'true' || value === true;
        } else {
            value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        }

        setNewProjectForm({ ...newProjectForm, [name]: value });
        setValues({ ...values, error: false, errorMessage: '' });
        setDisplayProject(false);
    };

    const handleDeleteTech = (ele) => {
        setProjectTech(projectTech.filter(e => e !== ele))
    }

    const handleProjectRequirement = name => event => {
        setProjectReqData({ ...projectReqData, [name]: event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1) })
    }

    const updateProjectTech = (data) => {
        setProjectTech(projectTech => [...projectTech, data]);
        setNewProjectForm({ ...newProjectForm, projectTechValue: '' })
    }

    const appendRequirements = (data) => {
        setProjectCurrentReq(projectCurrentReq => [...projectCurrentReq, data])
        setProjectReqData({ title: '', description: '' })
    }

    const handleNextClick = () => {
        if (projectTitle === '') {
            return setValues({ ...values, error: true, errorMessage: 'Required' })
        }
        if (projectDescription === '') {
            return setValues({ ...values, error: true, errorMessage: 'Required' })
        }
        if (projectTech.length === 0) {
            return setValues({ ...values, error: true, errorMessage: 'required' })
        }
        setDisplayForm2(true)
    }

    const deleteCurrentReq = (p) => {
        setProjectCurrentReq(projectCurrentReq.filter(e => JSON.stringify(e) !== JSON.stringify(p)))
    }

    const handleSubmitPeoject = (data) => {
        createProject(data).then(res => {
            // console.log(res)
            if (res) {
                setDisplayProject(true)
                setDisplayForm2(false)
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const getProject = () => {
        getUserProject(projectHost).then(res => {
            setAddedProjects(res.reverse())
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const prepareUpdate = ({ projectId, projectTitle, projectDescription, projectTech, projectCurrentReq, currency, budgetAmount, budgetDetails, isFlexible }) => {
        setNewProjectForm({
            ...newProjectForm, projectId: projectId, projectTitle: projectTitle, projectDescription: projectDescription,
            budgetAmount: budgetAmount,
            currency: currency,
            isFlexible: isFlexible,
            budgetDetails: budgetDetails
        })
        setProjectTech(projectTech)
        setProjectCurrentReq(projectCurrentReq)
        setDisplayForm2(false)
        setDisplayProjectTech(true)
        setDisplayProject(false)
        setUpdateFlag(true)
    }

    const handelDeleteProject = (projectId) => {
        deleteProject(projectId).then(res => {
            console.log(res)
            if (res) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleUpdateProject = (projectId, updatedData) => {
        updateProject(projectId, updatedData).then(res => {
            setRefresh(true)
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
        setDisplayProject(true)
        setDisplayForm2(false)
        setUpdateFlag(false)
        setNewProjectForm({
            ...newProjectForm,
            projectId: '',
            projectTitle: '',
            projectDescription: ''
        })
        setProjectTech([])
    }

    useEffect(() => {
        const userLanguage = navigator.language || navigator.userLanguage;

        if (userLanguage.includes('en-IN')) {
            setNewProjectForm({ ...newProjectForm, currency: 'INR' });
        } else if (userLanguage.includes('en-US')) {
            setNewProjectForm({ ...newProjectForm, currency: 'USD' });
        } else if (userLanguage.includes('en-GB')) {
            setNewProjectForm({ ...newProjectForm, currency: 'GBP' });
        } else if (userLanguage.includes('en-CA')) {
            setNewProjectForm({ ...newProjectForm, currency: 'CAD' });
        } else if (userLanguage.includes('en-AU')) {
            setNewProjectForm({ ...newProjectForm, currency: 'AUD' });
        } else {
            setNewProjectForm({ ...newProjectForm, currency: 'INR' });
        }
    }, []);

    return (
        <AuthNavbar>
            <AllowAdvancedAccount>
                <div className="container-fluid d-flex justify-content-center m-auto row py-5 gap-3">
                    {displayMessage ?
                        <div className="text-center">{success?.status === 'positive' ?
                            <span className="text-primary fs-3">{success?.success}</span> : <span className="text-danger fs-3">{success?.success}</span>}</div> : null}
                    <div className="container pb-md-4 pb-3">
                        <span className="col-1">
                            <Link to={'/advanced-dashboard'} className="" style={{ textDecoration: 'none' }}>
                                <button className="btn fw-bold btn-warning rounded-pill">
                                    <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">back to Dashboard</span>
                                </button>
                            </Link>
                        </span>
                    </div>
                    <div className="col-lg-5 shadow" style={{ height: '70vh', overflowY: 'scroll' }}>
                        <Collapse in={!displayform2}>
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center">
                                    {updateFlag ? <h2>Updating Project</h2> : <h2>Project Form</h2>}
                                </div>
                                <div className="d-flex justify-content-center">
                                    <CssTextField
                                        className='my-2 col-12'
                                        error={errorMessage === "" ? false : true}
                                        id="custom-css-outlined-input"
                                        autoComplete="off"
                                        color={error ? "error" : ""}
                                        value={projectTitle}
                                        label={errorMessage === '' ? "Project Title" : errorMessage}
                                        onChange={handleChange('projectTitle')}
                                        variant="outlined" />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <CssTextField
                                        className='my-2 col-12'
                                        error={errorMessage === "" ? false : true}
                                        id="custom-css-outlined-input"
                                        color={error ? "error" : ""}
                                        multiline
                                        rows={4}
                                        value={projectDescription}
                                        label={errorMessage === '' ? "Project Description" : errorMessage}
                                        onChange={handleChange('projectDescription')}
                                        variant="outlined" />
                                </div>
                                <div className="d-flex justify-content-center my-2">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Project Visibility</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={isPrivate}
                                            label="Select Visibility"
                                            onChange={handleChange('isPrivate')}
                                        >
                                            <MenuItem value={false}>Public</MenuItem>
                                            <MenuItem value={true}>Private</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="d-flex justify-content-center align-item-center">
                                    <div className="d-flex justify-content-between align-item-center row px-0 col-12">
                                        <div className="col-12 col-md-6 px-0">
                                            <CssTextField
                                                className='my-2 col-12'
                                                error={errorMessage === "" ? false : true}
                                                id="custom-css-outlined-input"
                                                color={error ? "error" : ""}
                                                value={projectTechValue}
                                                label={errorMessage === '' ? "Tech Used for project" : errorMessage}
                                                onChange={handleChange('projectTechValue')}
                                                variant="outlined" />
                                        </div>
                                        <button onClick={() => updateProjectTech(projectTechValue)} className="col-12 col-md-3 fw-bold btn btn-sm my-3 btn-primary rounded-pill">
                                            Add tech
                                        </button>
                                    </div>
                                </div>
                                <Collapse in={displayProjectTech}>
                                    <div className="d-flex justify-content-around">
                                        <div className="col-12 card bg-transparent border-primary">
                                            <div className="col-12 d-flex flex-wrap ">
                                                {projectTech.map((p, i) => (
                                                    <div key={i} className="bg-primary d-flex flex-wrap align-items-center rounded-pill p-1 px-2 m-2">
                                                        <span className="px-2 fw-bold text-white m-0">{p}</span>
                                                        <button className="btn btn-sm rounded-circle btn-danger" onClick={() => handleDeleteTech(p)}>
                                                            <FontAwesomeIcon size="1x" icon={faTrash} /></button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                                <div className="d-flex justify-content-center my-2">
                                    <CssTextField
                                        className='my-2 col-12'
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                        error={errorMessage === "" ? false : true}
                                        id="budget-amount-input"
                                        color={error ? "error" : ""}
                                        value={budgetAmount}
                                        label={errorMessage === '' ? "Project Budget Amount" : errorMessage}
                                        onChange={handleChange('budgetAmount')}
                                        variant="outlined"
                                    />
                                </div>
                                <div className="d-flex justify-content-center my-2">
                                    <FormControl fullWidth>
                                        <InputLabel id="currency-select-label">Currency</InputLabel>
                                        <Select
                                            labelId="currency-select-label"
                                            id="currency-select"
                                            value={currency}
                                            label="Currency"
                                            onChange={handleChange('currency')}
                                        >
                                            <MenuItem value="INR">INR (₹)</MenuItem>
                                            <MenuItem value="USD">USD ($)</MenuItem>
                                            <MenuItem value="EUR">EUR (€)</MenuItem>
                                            <MenuItem value="GBP">GBP (£)</MenuItem>
                                            <MenuItem value="CAD">CAD (C$)</MenuItem>
                                            <MenuItem value="AUD">AUD (A$)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="d-flex justify-content-center my-2">
                                    <FormControl fullWidth>
                                        <InputLabel id="is-flexible-label">Is the Budget Flexible?</InputLabel>
                                        <Select
                                            labelId="is-flexible-label"
                                            id="is-flexible"
                                            value={isFlexible}
                                            label="Is the Budget Flexible?"
                                            onChange={handleChange('isFlexible')}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <CssTextField
                                        className='my-2 col-12'
                                        error={errorMessage === "" ? false : true}
                                        id="budget-details-input"
                                        color={error ? "error" : ""}
                                        multiline
                                        rows={4}
                                        value={budgetDetails}
                                        label={errorMessage === '' ? "Additional Budget Details" : errorMessage}
                                        onChange={handleChange('budgetDetails')}
                                        variant="outlined"
                                    />
                                </div>

                                <div className="d-flex justify-content-center align-item-center py-3">
                                    <button onClick={() => handleNextClick()} className="btn btn-success fw-bold rounded-pill col-12">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={displayform2}>
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="m-0">Current Requirement</h2>
                                    <button onClick={() => setDisplayForm2(false)} className="btn btn-warning fw-bold rounded-pill col-4">
                                        <FontAwesomeIcon size="1x" icon={faArrowLeft} /> back to project form
                                    </button>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <CssTextField
                                        className='my-2 col-12'
                                        error={errorMessage === "" ? false : true}
                                        id="custom-css-outlined-input"
                                        color={error ? "error" : ""}
                                        value={title}
                                        label={errorMessage === '' ? "Current requirement" : errorMessage}
                                        onChange={handleProjectRequirement('title')}
                                        variant="outlined" />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <CssTextField
                                        className='my-2 col-12'
                                        error={errorMessage === "" ? false : true}
                                        id="custom-css-outlined-input"
                                        color={error ? "error" : ""}
                                        multiline
                                        rows={4}
                                        value={description}
                                        label={errorMessage === '' ? "Description" : errorMessage}
                                        onChange={handleProjectRequirement('description')}
                                        variant="outlined" />
                                </div>
                                <div className="d-flex justify-content-center align-item-center py-3">
                                    <div className="d-flex justify-content-center col-12">
                                        <div className="col-12">
                                            <div className="col-12 d-flex justify-content-between py-3">
                                                <button onClick={() => appendRequirements({ title, description })} className="btn btn-success fw-bold rounded-pill col-12">
                                                    Add Requirements
                                                </button>
                                            </div>
                                            {updateFlag ?
                                                <button onClick={() => handleUpdateProject(projectId, { projectHost, projectTitle, projectDescription, projectTech, isPrivate, projectCurrentReq, budget: { budgetAmount, isFlexible, budgetDetails, currency } })} className="btn btn-info fw-bold rounded-pill col-12">
                                                    Update
                                                </button> :
                                                <button onClick={() => handleSubmitPeoject({ projectHost, projectTitle, projectDescription, projectTech, isPrivate, projectCurrentReq, budget: { budgetAmount, isFlexible, budgetDetails, currency } })} className="btn btn-primary fw-bold rounded-pill col-12">
                                                    Submit
                                                </button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    <div className="col-lg-5 pb-5" style={{ height: '70vh', overflowY: 'scroll' }}>
                        <Collapse in={!displayProject}>
                            <div className="d-flex justify-content-center">
                                <div className="col-12">
                                    <div className="card border-0 bg-transparent">
                                        <div className="d-flex justify-content-between align-items-center">
                                            {updateFlag ? <h2></h2> : <h2>Adding New Project</h2>}
                                            {/* <button onClick={() => setDisplayProject(true)} className="btn col-4 btn-outline-warning rounded-pill">Back</button> */}
                                        </div>
                                        <div className="card border-primary bg-transparent p-2">
                                            <h3 className="fw-bold">{newProjectForm?.projectTitle}</h3>
                                            <div className="col-12 d-flex flex-wrap ">
                                                {projectTech.map((p, i) => (
                                                    <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                        <span className="fs-6 text-white fw-bold">{p}</span>
                                                    </div>
                                                ))}
                                            </div>
                                            <p className="m-0 fs-5">{newProjectForm?.projectDescription}</p>
                                            <div className="py-2 fw-bold">
                                                {(newProjectForm?.budgetAmount || newProjectForm?.budgetDetails) && (
                                                    <div>
                                                        <div>
                                                            {newProjectForm?.budgetAmount && (
                                                                <div className="fs-4">
                                                                    <span>Budget: </span>
                                                                    <span>
                                                                        {newProjectForm?.budgetAmount} {newProjectForm?.currency}{" "}
                                                                        <span className={`fs-6 ${newProjectForm?.isFlexible ? 'text-success' : 'text-danger'}`}>({newProjectForm?.isFlexible ? "Negotiable" : "Non-negotiable"})</span>
                                                                    </span>
                                                                </div>
                                                            )}
                                                            {newProjectForm?.budgetDetails && (
                                                                <div className="fw-normal">
                                                                    <span className="fw-bold">Budget Details: </span>
                                                                    <span>{newProjectForm?.budgetDetails}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                            <hr />
                                            <div className="">
                                                <h4 className="fw-bold">Project Current Requirements</h4>
                                                {projectCurrentReq.map((p, i) => (
                                                    <div key={i}>
                                                        <div className="d-flex align-items-start justify-content-between">
                                                            <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                                            <div className="col-9">
                                                                <p className="m-0 fs-5 fw-bold">{p?.title}</p>
                                                                <p className="m-0 fs-5">{p?.description}</p>
                                                            </div>
                                                            <div className="col-2 text-center">
                                                                <button onClick={() => deleteCurrentReq(p)} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={displayProject}>
                            <div className="d-flex justify-content-center">
                                <div className="col-12">
                                    <h2>Added Projects</h2>
                                    {addedProjects.map((a, i) => (
                                        <div key={i} className="card border-0 shadow bg-transparent my-3 p-2">
                                            <div className="d-flex col-12 justify-content-between align-items-center">
                                                <div>
                                                    <Link to={`single-project/${a._id}`} className="" state={a._id}><h3>{a.projectTitle}
                                                        {a.isDeleted ? <span> (Deleted)</span> : null}</h3></Link>
                                                    <p className={a?.isPrivate ? `text-danger` : `text-success`}>Visibility: {a?.isPrivate ? 'Public' : 'Private'}</p>
                                                </div>
                                                <div className="col-5 border-start border-3 d-flex justify-content-around align-items-center">
                                                    <button onClick={() => prepareUpdate({
                                                        projectId: a?._id,
                                                        projectTitle: a?.projectTitle,
                                                        projectDescription: a?.projectDescription,
                                                        projectTech: a?.projectTech,
                                                        projectCurrentReq: a?.projectCurrentReq,
                                                        budgetAmount: a?.budget?.budgetAmount,
                                                        currency: a?.budget?.currency,
                                                        isFlexible: a?.budget?.isFlexible,
                                                        budgetDetails: a?.budget?.budgetDetails
                                                    })} className={a.isDeleted ? "btn disabled btn-warning rounded-pill" : "btn btn-warning rounded-pill"}>
                                                        <span className="d-md-inline d-none">Update</span> <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => handelDeleteProject(a._id)}
                                                        className={a.isDeleted ? "btn disabled btn-danger rounded-pill" : "btn btn-danger rounded-pill"}>
                                                        <span className="d-md-inline d-none">Delete</span> <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex flex-wrap ">
                                                {a?.projectTech.map((p, i) => (
                                                    <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                        <span className="fs-6 text-white fw-bold">{p}</span>
                                                    </div>
                                                ))}
                                            </div>
                                            <p className="m-0 fs-5">{a?.projectDescription}</p>
                                            <hr />
                                            <div className="">
                                                <h4>Project Current Requirements</h4>
                                                {a?.projectCurrentReq.map((p, i) => (
                                                    <div key={i}>
                                                        <div className="d-flex align-items-start justify-content-start">
                                                            <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                                            <div className="col-9">
                                                                <p className="m-0 fs-5">{p?.title}</p>
                                                                <p className="m-0 fs-5">{p?.description}</p>
                                                            </div>
                                                        </div>
                                                        <hr className="mx-2 mx-md-5" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </AllowAdvancedAccount>
        </AuthNavbar>
    )
}

export default ManageProjects