import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import LanderPage from './authFlow/LanderPage'
import RegisterPage from './authFlow/RegisterPage'
import LoginPage from './authFlow/LoginPage'
import DashBoard from './AuthAccess/userFlow/Dashboard';
import ReviewPage from './AuthAccess/ReviewerFlow/ReviewPage';
import SingleUserReview from './AuthAccess/ReviewerFlow/SingleUserReview'
import SinglePostedReview from './AuthAccess/ReviewerFlow/SinglePostedReview';
import NoMatch from './NoMatch'
import PostReviewPage from './AuthAccess/ReviewerFlow/PostReviewPage';
import { PreventAuthFlow, ProtectRoute } from './manageRoutes/ProtectedRoutes';
import AOS from 'aos'
import { useEffect, useLayoutEffect } from 'react';
import AdvAccountDashboard from './AuthAccess/advancedAccount/Dashboard';
import ManageProjects from './AuthAccess/advancedAccount/ManageProjects';
import SingleProject from './AuthAccess/advancedAccount/SingleProject';
import UserViewProjectTeam from './AuthAccess/userFlow/UserViewProjectTeam';
import TeamRequested from './AuthAccess/userFlow/TeamsRequested';
import MyProfile from './AuthAccess/userFlow/MyProfile';
import TeamJoined from './AuthAccess/userFlow/TeamJoined';
import ProjectTasks from './AuthAccess/advancedAccount/ProjectTasks'
import ReviewerVerification from './AuthAccess/ReviewerFlow/ReviewerVerification';
import BlogLists from './freeAccess/BlogLists';
import SiteUnderDev from './freeAccess/SiteUnderDev';
import HowItWorks from './freeAccess/HowItWorks';
import SinglelBlog from './freeAccess/SingleBlog';
import PublicDisplayRankList from './freeAccess/PublicDisplayRankList';
import UserProjectTasks from './AuthAccess/userFlow/ProjectTasks';
import DisplayResults from './AuthAccess/advancedAccount/DisplayResults';
import UserSingleProject from './AuthAccess/userFlow/UserSingleProject';
import PublicProjectDisplay from './freeAccess/PublicProjectDisplay';
import PublicDisplaySingleProject from './freeAccess/PublicDisplaySingleProject';
import ManageTeam from './AuthAccess/advancedAccount/ManageTeam'
import AboutUs from './authFlow/About';
import Students from './freeAccess/Students';
import Freelancers from './freeAccess/Freelancers';
import ProjectListers from './freeAccess/ProjectListers';
import ContactUs from './freeAccess/ContactUs';
import CollaborativeEditor from './AuthAccess/editors/CollaborativeEditor';
import TeamRemoved from './AuthAccess/userFlow/TeamRemoved';
import AuthComponent from './authFlow/AuthComponent';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  }, [])

  useEffect(() => {
    // Set scroll restoration to manual
    window.history.scrollRestoration = 'manual';
  }, []);

  const location = useLocation()

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname])

  return (
    <Routes>
      <Route index element={<LanderPage />} />
      <Route path="auth" element={<PreventAuthFlow><AuthComponent /></PreventAuthFlow>} />
      <Route path='about-us' element={<AboutUs />} />
      <Route path='students' element={<Students />} />
      <Route path='freelancers' element={<Freelancers />} />
      <Route path='project-listers' element={<ProjectListers />} />
      <Route path='contact-us' element={<ContactUs />} />
      <Route path='blogs' element={<BlogLists />} />
      <Route path='single-blog/:blogId' element={<SinglelBlog />} />
      <Route path='public-display-rank-list' element={<PublicDisplayRankList />} />
      <Route path='under-development' element={<SiteUnderDev />} />
      <Route path='how-it-works' element={<HowItWorks />} />
      <Route path='display-public-project' element={<PublicProjectDisplay />} />
      <Route path='display-single-public-project/:pId' element={<PublicDisplaySingleProject />} />
      {/* <Route path='login' element={<PreventAuthFlow><LoginPage /></PreventAuthFlow>} /> */}
      <Route path='register' element={<PreventAuthFlow><RegisterPage /></PreventAuthFlow>} />
      <Route path='reviewer-verification' element={<ProtectRoute><ReviewerVerification /></ProtectRoute>} />
      <Route path='dashboard' element={<ProtectRoute><DashBoard /></ProtectRoute>} >
        <Route path='team-requested' element={<ProtectRoute><TeamRequested /></ProtectRoute>} />
        <Route path='teams-joined' element={<ProtectRoute><TeamJoined /></ProtectRoute>} />
        <Route path='teams-removed' element={<ProtectRoute><TeamRemoved /></ProtectRoute>} />
        <Route path='my-profile' element={<ProtectRoute><MyProfile /></ProtectRoute>} />
      </Route>
      <Route path="dashboard/teams-joined/single-project/:pId" element={<ProtectRoute><UserSingleProject /></ProtectRoute>} >
        <Route path="project-tasks" element={<ProtectRoute><UserProjectTasks /></ProtectRoute>} />
      </Route>
      <Route path="dashboard/teams-joined/view-single-project-team/:pId" element={<ProtectRoute><UserViewProjectTeam /></ProtectRoute>} />
      <Route path="review-lists" element={<ProtectRoute><ReviewPage /></ProtectRoute>} >
        <Route path=":rId" element={<ProtectRoute><SingleUserReview /></ProtectRoute>} />
      </Route>
      <Route path="post-review-lists" element={<ProtectRoute><PostReviewPage /></ProtectRoute>} >
        <Route path=":rId" element={<ProtectRoute><SinglePostedReview /></ProtectRoute>} />
      </Route>
      <Route path="advanced-dashboard" element={<ProtectRoute><AdvAccountDashboard /></ProtectRoute>} />
      <Route path="advanced-dashboard/manage-projects" element={<ProtectRoute><ManageProjects /></ProtectRoute>} />
      <Route path="advanced-dashboard/display-results" element={<ProtectRoute><DisplayResults /></ProtectRoute>} />
      <Route path="advanced-dashboard/manage-projects/single-project/:pId" element={<ProtectRoute><SingleProject /></ProtectRoute>} >
        <Route path="project-tasks" element={<ProtectRoute><ProjectTasks /></ProtectRoute>} />
      </Route>
      <Route path="advanced-dashboard/manage-projects/manage-single-project/:pId" element={<ProtectRoute><ManageTeam /></ProtectRoute>} />
      <Route path="advanced-dashboard/manage-projects/manage-single-project/project-code-editor/:pId" element={<ProtectRoute><CollaborativeEditor /></ProtectRoute>} />
      <Route path="dashboard/teams-joined/single-project/project-code-editor/:pId" element={<ProtectRoute><CollaborativeEditor /></ProtectRoute>} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default App;