import { useNavigate, Link, useParams } from "react-router-dom"
import AuthNavbar from "../AuthNavbar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react"
import { getProfiles, submitProfile } from "../../actions/userProfileActions"
import { Box, Chip, Collapse, FormControl, IconButton, InputLabel, OutlinedInput } from "@mui/material"
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { deSelectRequestedMember, fetchUsers, joinTeamRequest, getSingleProjectForTeam, submitCoHost, getUsersByTechnologies, removeTeamMember } from "../../actions/projectActions"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes"

const CssTextField = styled(TextField)({
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
        },
    },
    '& .MuiChip-label': {
        color: 'black'
    },
    '& .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiChip-deleteIcon .MuiChip-deleteIconMedium .MuiChip-deleteIconColorDefault .MuiChip-deleteIconOutlinedColorDefault': {
        borderColor: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: "black"
    }
});

const StyledSelect = styled(Select)(({ theme }) => ({
    color: 'black',
    label: 'black',
    icon: 'black',
    '& label': {
        color: '#0275d8',
    },
    '& .MuiSvgIcon-root': {
        color: 'black',
    },
}));


const ManageTeam = () => {
    const navigate = useNavigate()

    const project_id = useParams().pId

    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        // getProfiles().then(res => {
        //     setProfiles(res)
        // }).catch(err => {
        //     console.log(err)
        // })
        getSingleProjectForTeam(project_id).then(res => {
            setProjectData(res)
            getUsersByTechnologies(res.projectTech).then(res => {
                setRecomProfile(res)
            }).catch(err => {
                console.log("tech fetch user error", err)
            })
        }).catch(err => {
            console.log(err)
        })
    }, [refresh])

    const [projectData, setProjectData] = useState({})
    const [showTeam, setShowTeam] = useState(true)
    const [searchUsers, setSearchUsers] = useState(false)
    const [showSelectedUsers, setShowSelectedUsers] = useState(false)
    const [showUpdateCoHost, setShowUpdateCoHost] = useState(false)
    const [displayOutlet, setDisplayOutlet] = useState(false)
    const [fetchedUser, setFetchedUser] = useState([])
    const [teamCollapsible, setTeamCollapsible] = useState(-1)
    const [showCollapsible, setShowCollapsible] = useState(-1);
    const [showCollapsibleRemoved, setShowCollapsibleRemoved] = useState(-1);
    const [showCollapsibleReason, setShowCollapsibleReason] = useState(-1);
    const [showSelectedCollapsible, setShowSelectedCollapsible] = useState(-1);
    const [selectProjectCoHost, setSelectProjectCoHost] = useState('')
    const [recomProfile, setRecomProfile] = useState([])

    const [showSelectForm, setShowSelectForm] = useState(-1)

    const [values, setValues] = useState({
        text: '',
        error: false,
        errorMessage: ''
    })

    const { text, error, errorMessage } = values

    const handleChange = name => event => {
        setValues({ ...values, error: false, errorMessage: '', [name]: event.target.value })
        if (event.target.value.length <= 2) {
            setFetchedUser([])
        } else {
            fetchUsers(event.target.value).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }
    useEffect(() => {
        if (text.length >= 2) {
            fetchUsers(text).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [text.length <= 2])

    const handleDeSelectRequestedMember = (projectId, selectedId) => {
        deSelectRequestedMember({ projectId, selectedId }).then(res => {
            if (res) {
                // navigate('/advanced-dashboard/manage-projects', {state:{status: 'negative', success : "User Deselected successfully"}})
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleRemoveTeamMember = (projectId, selectedId) => {
        removeTeamMember({ projectId, selectedId, removalReason }).then(res => {
            if (res) {
                // navigate('/advanced-dashboard/manage-projects', {state:{status: 'negative', success : "User Deselected successfully"}})
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handlePostSearch = (text) => {
        if (text.length >= 2) {
            fetchUsers(text).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const [sudoRequest, setSudoRequest] = useState([])
    const [sudoUserRequestIds, setSudoUserRequestIds] = useState([])

    // const handleAddTeamRequest = (userId) => {
    //     setSudoRequest(sudoRequest => [...sudoRequest, userId])
    //     setSudoUserRequestIds(sudoUserRequestIds => [...sudoUserRequestIds, userId.userId._id])
    // }

    const handleAddTeamRequest = (user) => {
        // Update the sudoUserRequestIds array by checking if the userId already exists
        setSudoUserRequestIds((sudoUserRequestIds) => {
            const existingIndex = sudoUserRequestIds.findIndex(req => req.userId === user.userId._id);

            if (existingIndex !== -1) {
                // If userId exists, update the amount and requirements
                const updatedRequests = [...sudoUserRequestIds];
                updatedRequests[existingIndex] = {
                    ...updatedRequests[existingIndex],
                    amount,
                    requirements: selectedReqs,
                };
                return updatedRequests;
            } else {
                // If userId doesn't exist, add a new entry
                return [
                    ...sudoUserRequestIds,
                    {
                        userId: user.userId._id,
                        amount,
                        requirements: selectedReqs,
                    },
                ];
            }
        });

        // Update the sudoRequest array to prevent duplicates
        setSudoRequest((sudoRequest) => {
            if (!sudoRequest.find(req => req.userId === user.userId._id)) {
                // Add the user if it's not already in sudoRequest
                return [...sudoRequest, user];
            }
            return sudoRequest; // Return the same array if the user already exists
        });

        // Clear the amount and selected requirements for the next selection
        setAmount('');
        setSelectedReqs([]);
    };

    const onSubmitRequests = (projectId, selectedRequests) => {
        const payload = selectedRequests.map(req => ({
            userId: req.userId,
            amount: req.amount,
            requirements: req.requirements.map(reqItem => ({
                title: reqItem.title,
                desc: reqItem.desc,
                _id: reqItem._id
            }))
        }));

        joinTeamRequest({ projectId, selectedIds: payload })
            .then(res => {
                if (res) {
                    setRefresh(true);
                    setShowSelectedUsers(false);
                    setSudoRequest([]);
                    setSudoUserRequestIds([]);
                }
            })
            .catch(err => {
                console.log(err);
            });

        setRefresh(false);
    };


    const [removalReason, setRemovalReason] = useState(''); // Store the reason for removal
    const [showModal, setShowModal] = useState(-1); // Control modal visibility

    const handleConfirmRemove = (projectId, memberId, removalReason) => {
        handleRemoveTeamMember(projectId, memberId, removalReason);
    };
    const handleRemoveTeamRequest = (userId) => {
        setSudoRequest(sudoRequest.filter(e => JSON.stringify(e) !== JSON.stringify(userId)))
        setSudoUserRequestIds(sudoUserRequestIds.filter(e => JSON.stringify(e) !== JSON.stringify(userId.userId._id)))
    }

    const handleChangeCoHost = (event) => {
        setSelectProjectCoHost(event.target.value)
    }

    const showRequestButton = ({ projectData, profile }) => {
        let i
        for (i = 0; i <= projectData.projectTeam.length; i++) {
            if (profile.userId._id && profile.userId._id === projectData.projectTeam[i]?._id) {
                return (<div>Already Added</div>)
            }
        }
        for (i = 0; i <= projectData.projectTeamRequested.length; i++) {
            if (profile.userId._id && profile.userId._id === projectData.projectTeamRequested[i]?._id) {
                return (<div>User Requested</div>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id && profile.userId._id === sudoRequest[i]?.userId._id) {
                return (<button onClick={() => handleRemoveTeamRequest(profile)} className="btn fw-bold btn-danger rounded-pill">
                    Deselect
                </button>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id !== sudoRequest[i]?.userId._id) {
                return (
                    <button
                        onClick={() => {
                            handlePostSearch(text)
                            setSearchUsers(true)
                            setShowSelectedUsers(true)
                            handleAddTeamRequest(profile)
                            setShowSelectForm(-1)
                        }} className="btn btn-success fw-bold rounded-pill">
                        Select
                    </button>
                )
            }
        }
    }

    const showRequestFormButton = ({ projectData, profile, index }) => {
        let i
        for (i = 0; i <= projectData.projectTeam.length; i++) {
            if (profile.userId._id && profile.userId._id === projectData.projectTeam[i]?._id) {
                return (<div>Already Added</div>)
            }
        }
        for (i = 0; i <= projectData.projectTeamRequested.length; i++) {
            if (profile.userId._id && profile.userId._id === projectData.projectTeamRequested[i]?._id) {
                return (<div>User Requested</div>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id && profile.userId._id === sudoRequest[i]?.userId._id) {
                return (<button onClick={() => handleRemoveTeamRequest(profile)} className="btn fw-bold btn-danger rounded-pill">
                    Deselect
                </button>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id !== sudoRequest[i]?.userId._id) {
                return (

                    showSelectForm === index ? (
                        <button onClick={() => {
                            setShowSelectForm(-1)
                        }} className="btn btn-danger rounded-pill fw-bold">
                            Close
                        </button>
                    ) : (
                        <button onClick={() => {
                            setShowSelectForm(index)
                        }} className="btn btn-success rounded-pill fw-bold">
                            Select
                        </button>
                    )

                )
            }
        }
    }

    const handleSubmitCoHost = ({ projectId, userId }) => {
        submitCoHost({ projectId, userId }).then(res => {
            if (res) {
                setRefresh(true)
                setShowUpdateCoHost(false)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const [amount, setAmount] = useState('');
    const [selectedReqs, setSelectedReqs] = useState([]);

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleReqChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedReqs(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleRemoveReq = (reqId, event) => {
        event.stopPropagation();  // Prevent triggering the dropdown
        setSelectedReqs(selectedReqs.filter((req) => req._id !== reqId));
    };

    console.log(selectedReqs)

    return (
        <AuthNavbar>
            <AllowAdvancedAccount>
                <div className=" px-md-5 px-2 py-5">
                    <Link to={`/advanced-dashboard/manage-projects/single-project/${projectData._id}`} className="" style={{ textDecoration: 'none' }}>
                        <button className="btn btn-warning col-md-1 fw-bold rounded-pill">
                            <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">back</span>
                        </button>
                    </Link>
                </div>
                <div className="container-fluid d-flex align-items-start m-auto row pb-5">
                    <div className={"col-lg-6 order-lg-1 order-2"}>
                        <div>
                            <h3 className="fw-bold">Fetch your perfect team here</h3>
                        </div>
                        <div>
                            <div className="col-12 my-2">
                                <CssTextField
                                    className=' col-12'
                                    error={errorMessage === "" ? false : true}
                                    id="custom-css-outlined-input"
                                    color={error ? "error" : ""}
                                    value={text}
                                    autoComplete='off'
                                    label={errorMessage === '' ? "Enter single tech to search user" : errorMessage}
                                    onChange={handleChange('text')}
                                    variant="outlined" />
                                <label className="text-danger fs-6">*Type single tech to fetch users</label>
                            </div>
                        </div>
                        <div className="">
                            {fetchedUser.length === 0 ? <div className="conatiner-fluid card border-0 shadow-sm p-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                <div className="pt-2 fw-bold">
                                    <h3>Recommended by devSemble: </h3>
                                    <div className="pb-2 d-flex flex-wrap">{projectData?.projectTech?.map((t, i) => (
                                        <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                            <span className="">{t}</span>
                                        </div>
                                    ))}</div>
                                </div>
                                <hr className="mt-0" />
                                {recomProfile.map((f, i) => (
                                    <div key={i} style={{
                                        border: 'solid #0257d8',
                                        borderWidth: 'thin'
                                    }} className="bg-light p-2 my-1 rounded">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                            <div className="col-12 col-sm-7 d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-6 fs-5 fw-bold ">{f?.userId.firstName} {f?.userId.lastName}</div>
                                                <div className="col-6 fw-bold">
                                                    {f?.rankId?.rankRange === null ? 'Not Ranked' : <>{f?.rankId?.rankRange}</>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                {showRequestFormButton({ projectData: projectData, profile: f, index: i })}
                                                {showCollapsible === i ?
                                                    <button onClick={() => setShowCollapsible(-1)} className="btn btn-danger fw-bold rounded-pill">
                                                        Close
                                                    </button> :
                                                    <button onClick={() => setShowCollapsible(i)} className="btn btn-primary fw-bold rounded-pill">
                                                        View Profile
                                                    </button>}
                                            </div>
                                        </div>
                                        <div className="">
                                            <Collapse in={showSelectForm === i}>
                                                <div className="py-2 d-flex flex-wrap justify-content-between align-items-start gap-3">
                                                    <div className="col-12">
                                                        {/* Amount Input Field */}
                                                        <CssTextField
                                                            className="col-12 my-2"
                                                            variant="outlined"
                                                            value={amount}
                                                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                            type="number"
                                                            onChange={handleAmountChange}
                                                            label="Amount"
                                                            multiline
                                                            maxRows="4"
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        {/* Multiple Select for Current Requirements */}
                                                        <FormControl variant="outlined" className="col-12">
                                                            <InputLabel id="requirement-label">Select Requirements</InputLabel>
                                                            <Select
                                                                labelId="requirement-label"
                                                                id="requirement-select"
                                                                multiple
                                                                value={selectedReqs}
                                                                onChange={handleReqChange}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Select Requirements" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected.map((req) => (
                                                                            <Chip
                                                                                key={req._id}
                                                                                label={req.title}
                                                                                className="bg-primary text-white fw-bold fs-6"
                                                                            // deleteIcon={
                                                                            //     <IconButton
                                                                            //         size="small"
                                                                            //         onClick={(event) => handleRemoveReq(req._id, event)}
                                                                            //         aria-label="delete"
                                                                            //     >
                                                                            //         <FontAwesomeIcon className="text-danger" size="sm" icon={faTrash} />
                                                                            //     </IconButton>
                                                                            // }
                                                                            // onDelete={(event) => handleRemoveReq(req._id, event)}  // Add event parameter
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                            >
                                                                {/* Mapping over projectCurrentReq to display in the select */}
                                                                {projectData.projectCurrentReq.map((req) => (
                                                                    <MenuItem key={req._id} value={req}>
                                                                        {req.title}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-12 d-flex flex-wrap justify-content-end">
                                                        <div className="">
                                                            {showRequestButton({ projectData: projectData, profile: f })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <Collapse className="" in={showCollapsible === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                            <div>
                                                                {f?.workExprience.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{w.title}</span>
                                                                        <div className="d-flex flex-wrap ">
                                                                            {w?.techUsed.map((t, i) => (
                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                    <span className="">{t}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                            <div>
                                                                {f?.educationExprience.map((e, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        {/* <hr /> */}
                                    </div>
                                ))}
                            </div> : <div className="conatiner-fluid card p-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                {fetchedUser.map((f, i) => (
                                    <div key={i} style={{
                                        border: 'solid #0257d8',
                                        borderWidth: 'thin'
                                    }} className="bg-light p-2 my-1 rounded">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                            <div className="col-12 col-sm-7 d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-6 fs-5 fw-bold ">{f?.userId.firstName} {f?.userId.lastName}</div>
                                                <div className="col-6 fw-bold">
                                                    {!f?.rankId?.rankRange === null ? 'Not Ranked' : <>{f?.rankId?.rankRange}</>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                {showRequestFormButton({ projectData: projectData, profile: f, index: i })}
                                                {showCollapsible === i ?
                                                    <button onClick={() => setShowCollapsible(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                        Close
                                                    </button> :
                                                    <button onClick={() => setShowCollapsible(i)} className="btn fw-bold btn-primary rounded-pill">
                                                        View Profile
                                                    </button>}
                                            </div>
                                        </div>
                                        <div className="">
                                            <Collapse in={showSelectForm === i}>
                                                <div className="py-2 d-flex flex-wrap justify-content-between align-items-start gap-3">
                                                    <div className="col-12">
                                                        {/* Amount Input Field */}
                                                        <CssTextField
                                                            className="col-12 my-2"
                                                            variant="outlined"
                                                            value={amount}
                                                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                            type="number"
                                                            onChange={handleAmountChange}
                                                            label="Amount"
                                                            multiline
                                                            maxRows="4"
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        {/* Multiple Select for Current Requirements */}
                                                        <FormControl variant="outlined" className="col-12">
                                                            <InputLabel id="requirement-label">Select Requirements</InputLabel>
                                                            <Select
                                                                labelId="requirement-label"
                                                                id="requirement-select"
                                                                multiple
                                                                value={selectedReqs}
                                                                onChange={handleReqChange}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Select Requirements" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected.map((req) => (
                                                                            <Chip
                                                                                key={req._id}
                                                                                label={req.title}
                                                                                className="bg-primary text-white fw-bold fs-6"
                                                                            // deleteIcon={
                                                                            //     <IconButton
                                                                            //         size="small"
                                                                            //         onClick={(event) => handleRemoveReq(req._id, event)}
                                                                            //         aria-label="delete"
                                                                            //     >
                                                                            //         <FontAwesomeIcon className="text-danger" size="sm" icon={faTrash} />
                                                                            //     </IconButton>
                                                                            // }
                                                                            // onDelete={(event) => handleRemoveReq(req._id, event)}  // Add event parameter
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                            >
                                                                {/* Mapping over projectCurrentReq to display in the select */}
                                                                {projectData.projectCurrentReq.map((req) => (
                                                                    <MenuItem key={req._id} value={req}>
                                                                        {req.title}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-12 d-flex flex-wrap justify-content-end">
                                                        <div className="">
                                                            {showRequestButton({ projectData: projectData, profile: f })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <Collapse className="" in={showCollapsible === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                            <div>
                                                                {f?.workExprience.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{w.title}</span>
                                                                        <div className="d-flex flex-wrap ">
                                                                            {w?.techUsed.map((t, i) => (
                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                    <span className="">{t}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                            <div>
                                                                {f?.educationExprience.map((e, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-2 order-2">
                        <Collapse in={!searchUsers || sudoRequest.length === 0}>
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                <div className="col-12 pb-3 d-flex row justify-content-around align-items-start">
                                    <div>
                                        {projectData.projectCoHost === undefined ? <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            {projectData?.projectTeam?.length >= 2 ? <>
                                                <span className="fs-4 fw-bold">Select Co-Host</span>
                                                <div className="col-6">
                                                    <StyledSelect
                                                        className="col-12"
                                                        labelId="my-select-label"
                                                        id="demo-simple-select"
                                                        value={selectProjectCoHost}
                                                        label="Select Co-Host"
                                                        variant="outlined"
                                                        onChange={handleChangeCoHost}
                                                    >
                                                        {projectData.projectTeam?.map((p, i) => (
                                                            <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </div>
                                                <button
                                                    onClick={() => handleSubmitCoHost({ projectId: projectData._id, userId: selectProjectCoHost })}
                                                    className="btn btn-primary fw-bold rounded-pill">Add as Co-Host</button>
                                            </> : <span className="text-danger fw-bold">*For Co-host Need atleast 2 team members</span>}
                                        </div> : <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <span className="fs-3 fw-bold">Project Co Host: {projectData.projectCoHost?.firstName} {projectData.projectCoHost?.lastName}</span>
                                            {showUpdateCoHost ?
                                                <button onClick={() => setShowUpdateCoHost(false)} className="btn btn-danger rounded-pill">Close</button> :
                                                <button onClick={() => setShowUpdateCoHost(true)} className="btn fw-bold btn-info rounded-pill">Update Co-host</button>
                                            }
                                        </div>}
                                        <Collapse in={showUpdateCoHost}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <span className="fs-4 fw-bold">Select Co-Host</span>
                                                <div className="col-6">
                                                    <StyledSelect
                                                        className="col-12"
                                                        labelId="my-select-label"
                                                        id="demo-simple-select"
                                                        value={selectProjectCoHost}
                                                        label="Select Co-Host"
                                                        variant="outlined"
                                                        onChange={handleChangeCoHost}
                                                    >
                                                        {projectData.projectTeam?.map((p, i) => (
                                                            <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </div>
                                                <button
                                                    onClick={() => handleSubmitCoHost({ projectId: projectData._id, userId: selectProjectCoHost })}
                                                    className="btn fw-bold btn-primary rounded-pill">Add as Co-Host</button>
                                            </div>
                                        </Collapse>
                                        <hr className="m-1" />
                                    </div>
                                    <div className="col-12 d-flex justify-content-between align-items-center">
                                        <span className="fs-3"><b>Team Members</b></span>
                                    </div>
                                    <div className="card p-2 m-2 col-12 bg-transparent border-primary rounded-lg">
                                        {projectData.projectTeam?.length === 0 ? <div>
                                            <span>No Team members</span>
                                        </div> : <div>
                                            {projectData.projectTeam?.map((p, i) => (
                                                <div key={i} style={{
                                                    border: 'solid #0257d8',
                                                    borderWidth: 'thin'
                                                }} className="bg-light p-2 my-1 rounded">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                        <div className="col-12 col-md-7">
                                                            <span className="col-6 fs-5 fw-bold ">{p?.firstName} {p?.lastName}</span>
                                                        </div>
                                                        <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                            {showModal === i ?
                                                                <button
                                                                    onClick={() => setShowModal(-1)}
                                                                    className="btn fw-bold btn-danger rounded-pill"
                                                                >
                                                                    Close
                                                                </button> :
                                                                <button
                                                                    onClick={() => setShowModal(i)}
                                                                    disabled={p._id === projectData?.authorizedGitAccess || p._id === projectData?.projectCoHost?._id}
                                                                    className="btn fw-bold btn-danger rounded-pill"
                                                                >
                                                                    Remove
                                                                </button>}
                                                            {teamCollapsible === i ?
                                                                <button onClick={() => setTeamCollapsible(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                                    Close
                                                                </button> :
                                                                <button onClick={() => setTeamCollapsible(i)} className="btn fw-bold btn-primary rounded-pill">
                                                                    View Profile
                                                                </button>}
                                                        </div>
                                                    </div>
                                                    <Collapse in={showModal === i}>
                                                        <div className="py-2">
                                                            <div className="">
                                                                <h5>Remove Team Member</h5>
                                                                <p>Please provide a reason for removing this team member:</p>
                                                                <CssTextField
                                                                    className="col-12"
                                                                    variant="outlined"
                                                                    value={removalReason}
                                                                    onChange={(e) => setRemovalReason(e.target.value)}
                                                                    label="Enter reason for removal"
                                                                    multiline
                                                                    maxRows="4"
                                                                />
                                                                <div className="py-1 text-end">
                                                                    <button
                                                                        onClick={() => handleRemoveTeamMember(projectData._id, p._id)}
                                                                        className="btn btn-danger rounded-pill fw-bold"
                                                                        disabled={!removalReason.trim()}
                                                                    >
                                                                        Confirm Removal
                                                                    </button>
                                                                    <button
                                                                        onClick={() => setShowModal(-1)}
                                                                        className="btn btn-secondary rounded-pill fw-bold ms-md-2"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <Collapse className="" in={teamCollapsible === i}>
                                                        <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Work Exprience</b></span>
                                                                    <div>
                                                                        {p?.devSembleProfile?.workExprience.map((w, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{w.title}</span>
                                                                                <div className="d-flex flex-wrap ">
                                                                                    {w?.techUsed.map((t, i) => (
                                                                                        <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                            <span className="">{t}</span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Education Exprience</b></span>
                                                                    <div>
                                                                        {p?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{e.eduTitle}</span>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </div>}
                                    </div>
                                    <span className="fs-3"><b>Users Requested</b></span>
                                    <div className="card p-2 m-2 col-12 bg-transparent border-primary rounded-lg">
                                        {projectData.projectTeamRequested?.length === 0 ? <div>
                                            <span>No one requested</span>
                                        </div> : <div>
                                            {projectData.projectTeamRequested?.map((p, i) => (
                                                <div key={i} style={{
                                                    border: 'solid #0257d8',
                                                    borderWidth: 'thin'
                                                }} className="bg-light p-2 my-1 rounded">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                        <div className="col-12 col-md-7">
                                                            <span className="col-6 fs-5 fw-bold ">{p?.firstName} {p?.lastName}</span>
                                                        </div>
                                                        <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                            <button onClick={() => handleDeSelectRequestedMember(projectData._id, p._id)} className="btn fw-bold btn-danger rounded-pill">
                                                                Deselect
                                                            </button>
                                                            {showCollapsible === i ?
                                                                <button onClick={() => setShowCollapsible(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                                    Close
                                                                </button> :
                                                                <button onClick={() => setShowCollapsible(i)} className="btn fw-bold btn-primary rounded-pill">
                                                                    View Profile
                                                                </button>}
                                                        </div>
                                                    </div>
                                                    <Collapse className="" in={showCollapsible === i}>
                                                        <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Work Exprience</b></span>
                                                                    <div>
                                                                        {p?.devSembleProfile?.workExprience.map((w, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{w.title}</span>
                                                                                <div className="d-flex flex-wrap ">
                                                                                    {w?.techUsed.map((t, i) => (
                                                                                        <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                            <span className="">{t}</span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Education Exprience</b></span>
                                                                    <div>
                                                                        {p?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{e.eduTitle}</span>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </div>}
                                    </div>
                                    <span className="fs-3"><b>Members Removed</b></span>
                                    <div className="card p-2 m-2 col-12 bg-transparent border-primary rounded-lg">
                                        {projectData.membersRemoved?.length === 0 ? <div>
                                            <span>Well you have a strong team then</span>
                                        </div> : <div>
                                            {projectData.membersRemoved?.map((p, i) => (
                                                <div key={i} style={{
                                                    border: 'solid #0257d8',
                                                    borderWidth: 'thin'
                                                }} className="bg-light p-2 my-1 rounded">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                        <div className="col-12 col-md-7">
                                                            <span className="col-6 fs-5 fw-bold ">{p?.member?.firstName} {p?.member?.lastName}</span>
                                                        </div>
                                                        <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                            {/* {showRequestButton({ projectData: projectData, profile: p?.member?.devSembleProfile })} */}
                                                            {showCollapsibleReason === i ?
                                                                <button onClick={() => setShowCollapsibleReason(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                                    Close
                                                                </button> :
                                                                <button onClick={() => setShowCollapsibleReason(i)} className="btn fw-bold btn-warning rounded-pill">
                                                                    View Reason
                                                                </button>
                                                            }
                                                            {showCollapsibleRemoved === i ?
                                                                <button onClick={() => setShowCollapsibleRemoved(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                                    Close
                                                                </button> :
                                                                <button onClick={() => setShowCollapsibleRemoved(i)} className="btn fw-bold btn-primary rounded-pill">
                                                                    View Profile
                                                                </button>}
                                                        </div>
                                                    </div>
                                                    <Collapse in={showCollapsibleReason === i} >
                                                        <div className="py-2">
                                                            {p.removalReason}
                                                        </div>
                                                    </Collapse>
                                                    <Collapse className="" in={showCollapsibleRemoved === i}>
                                                        <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Work Exprience</b></span>
                                                                    <div>
                                                                        {p?.member?.devSembleProfile?.workExprience.map((w, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{w.title}</span>
                                                                                <div className="d-flex flex-wrap ">
                                                                                    {w?.techUsed.map((t, i) => (
                                                                                        <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                            <span className="">{t}</span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Education Exprience</b></span>
                                                                    <div>
                                                                        {p?.member?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{e.eduTitle}</span>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={sudoRequest.length > 0}>
                            <div className="card border-0 bg-transparent my-3 p-2">
                                <div className="d-flex flex-wrap justify-content-between align-items-start">
                                    <div className="">
                                        <span className="fs-3 fw-bold">Team Members you selected</span>
                                    </div>
                                    {sudoRequest.length === 0 ? null :
                                        <button onClick={() => onSubmitRequests(projectData._id, sudoUserRequestIds)} className="btn my-2 btn-success col-md-4 rounded-pill">
                                            <span className="ps-lg-3 fw-bold">Send Requests</span>
                                        </button>}
                                </div>
                                <div className="conatiner-fluid card p-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                    {sudoRequest.map((s, i) => (
                                        <div key={i} style={{
                                            border: 'solid #0257d8',
                                            borderWidth: 'thin'
                                        }} className="bg-light p-2 my-1 rounded">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-6">
                                                    <span className="fs-4 fw-bold">{s.userId.firstName} {s.userId.lastName}</span>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex justify-content-between align-items-center">
                                                    <button onClick={() => handleRemoveTeamRequest(s)} className="btn fw-bold btn-danger rounded-pill">
                                                        Deselect
                                                    </button>
                                                    {showSelectedCollapsible === i ?
                                                        <button onClick={() => setShowSelectedCollapsible(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                            Close
                                                        </button> :
                                                        <button onClick={() => setShowSelectedCollapsible(i)} className="btn fw-bold btn-primary rounded-pill">
                                                            View Profile
                                                        </button>}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div>
                                                </div>
                                                <Collapse className="" in={showSelectedCollapsible === i}>
                                                    <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                        <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                            <div>
                                                                <span className="fs-4"><b>Work Exprience</b></span>
                                                                <div>
                                                                    {s?.workExprience.map((w, i) => (
                                                                        <div key={i} className="">
                                                                            <span className="fs-4">{w.title}</span>
                                                                            <div className="d-flex flex-wrap ">
                                                                                {w?.techUsed.map((t, i) => (
                                                                                    <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                        <span className="">{t}</span>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <hr className="mb-2" />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                            <div>
                                                                <span className="fs-4"><b>Education Exprience</b></span>
                                                                <div>
                                                                    {s?.educationExprience.map((e, i) => (
                                                                        <div key={i} className="">
                                                                            <span className="fs-4">{e.eduTitle}</span>
                                                                            <hr className="mb-2" />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </AllowAdvancedAccount>
        </AuthNavbar>
    )
}

export default ManageTeam