// import DashBoard from "./Dashboard"
import { Collapse, IconButton } from "@mui/material"
import { useState } from "react"
import { Link } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [displayCommunity, setDisplayCommuntiy] = useState(false)

    return (
        <div>
            <div className="py-2" style={{ backgroundColor: 'transparent' }}>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="bg-white p-2 px-3 col-11 col-lg-10 border-0 rounded-pill d-flex justify-content-between align-items-center"
                        style={{ boxShadow: "0rem 0rem 1rem #0275d8", wordWrap: 'break-word' }}>
                        <div className="mx-lg-auto ml-auto">
                            <Link to="/" className="text-decoration-none">
                                <h2 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>devSemble</h2>
                            </Link>
                        </div>
                        <div>
                            <div className="d-lg-none d-block">
                                <IconButton onClick={() => setIsOpen(!isOpen)} >
                                    <MenuIcon />
                                </IconButton>
                                <div className="col-12 d-flex flex-wrap justify-content-center" style={{ position: 'absolute', right: '0px', zIndex: 2 }}>
                                    <Collapse in={isOpen} className=" col-10 d-flex flex-wrap justify-content-center">
                                        <div className="p-2 m-2 rounded bg-white"
                                            style={{ boxShadow: "0rem 0.5rem 1rem #0275d8", wordWrap: 'break-word' }}>
                                            <div className="" style={{ textAlign: 'right' }}>
                                                <div className="py-2">
                                                    <Link to="/about-us" className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>About Us</h5>
                                                    </Link>
                                                </div>
                                                <div className="py-2">
                                                    <Link to="/blogs" className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Explore Blogs</h5>
                                                    </Link>
                                                </div>
                                                <div className="py-2">
                                                    <Link onClick={() => setDisplayCommuntiy(!displayCommunity)} className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Community</h5>
                                                    </Link>
                                                    <Collapse in={displayCommunity}>
                                                        <div className="">
                                                            <ul className="list-unstyled text-muted">
                                                                <li>
                                                                    <Link to="/students" className="">
                                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Students</h5>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/freelancers" className="">
                                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Freelancers</h5>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/project-listers" className="">
                                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Project Listers</h5>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                <div className="py-2">
                                                    <a href="/auth" className="text-decoration-none text-dark col-12 rounded-pill ">
                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Get Started</h5>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                            <div className="d-lg-flex d-none flex-wrap justify-content-between align-items-center">
                                <div className="px-2">
                                    <Link to="/about-us" className="text-decoration-none btn btn-sm btn-success rounded-pill">
                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>About Us</h5>
                                    </Link>
                                </div>
                                <div className="px-2">
                                    <Link to="/blogs" className="text-decoration-none btn btn-sm btn-success rounded-pill">
                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Explore Blogs</h5>
                                    </Link>
                                </div>
                                <div className="px-2">
                                    <button onClick={() => setDisplayCommuntiy(!displayCommunity)} className="text-decoration-none btn btn-sm btn-success rounded-pill">
                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Community</h5>
                                    </button>
                                    <Collapse in={displayCommunity} className="position-absolute">
                                        <div className="bg-white shadow">
                                            <ul className="list-unstyled text-muted">
                                                <li className="py-2 p-2">
                                                    <Link to="/students" className="text-decoration-none">
                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Students</h5>
                                                    </Link>
                                                </li>
                                                <li className="py-2 p-2">
                                                    <Link to="/freelancers" className="text-decoration-none">
                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Freelancers</h5>
                                                    </Link>
                                                </li>
                                                <li className="py-2 p-2">
                                                    <Link to="/project-listers" className="text-decoration-none">
                                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Project Listers</h5>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="px-2">
                                    <a href="/auth" className="text-decoration-none btn btn-sm btn-success rounded-pill">
                                        <h5 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>Get Started</h5>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar