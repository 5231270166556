import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import { useAuthStore } from '../../store/store';
import TextField from '@mui/material/TextField';
import { Autocomplete, FormControl } from '@mui/material';
import { useEffect, useState } from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Collapse } from '@mui/material';
import dayjs from 'dayjs';
import { submitProfile } from '../../actions/userProfileActions';
import AuthNavbar from "../AuthNavbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import { getRepos, postGitCreds } from "../../actions/gitOAuthActions";
import { gitUrl } from "../../constants";

const DashBoard = () => {

    const data = useAuthStore(state => state.user)
    // const updateLocalUser = useAuthStore(state => state.updateUserProfileStatus)
    const setStateUser = useAuthStore(state => state.setUser)
    const navigate = useNavigate()

    const [isUpdate, setIsUpdate] = useState(false)
    const [values, setValues] = useState({
        text: '',
        errorMessage: '',
        error: ''
    })

    useEffect(() => {
        redirect()
    }, [])

    const redirect = () => {
        if (data.user.isReviewer === true) {
            return navigate('/review-lists')
        }
        if (data.user.isAdvancedAccount === true) {
            return navigate('/advanced-dashboard')
        }
    }

    const [workDisplay, setWorkDisplay] = useState(false)
    const [workFormDisplay, setWorkFormDisplay] = useState(true)
    const [eduDisplay, setEduDisplay] = useState(false)
    const [eduFormDisplay, setEduFormDisplay] = useState(false)
    const [otherLinksForm, setOtherLinkForm] = useState(false)
    const [displayOtherLink, setDisplayOtherLink] = useState(false)
    const [displayProfile, setDisplayProfile] = useState(true)
    const [displayViewData, setDisplayViewData] = useState(false)
    // const [verifiedSuccessStack, setVerifiedSuccessStack] = useState(false)
    // const [verifiedSuccessGit, setVerifiedSuccessGit] = useState(false)
    // const [loadingStack, setLoadingStack] = useState(false)
    // const [loadingGit, setLoadingGit] = useState(false)

    const [wvd, setwvd] = useState(false)
    const [evd, setevd] = useState(false)
    const [lvd, setlvd] = useState(false)

    const [index, setIndex] = useState(null)

    // const [stackUserName, setStackUserName] = useState('16819973')
    // const [gitUserName, setGitUserName] = useState('mihir06121')

    const [work, setWork] = useState({
        title: 'project 1',
        description: 'description 1',
        startDate: '',
        endDate: '',
        workLink: '',
        repoName: '',
        repoLink: '',
        currentlyPursuing: '',
        techValue: '',
        errorMessageWork: '',
        errorWork: ''
    })
    const [techUsed, setTechUsed] = useState([])

    const [education, setEducation] = useState({
        eduTitle: 'education 1',
        eduDescription: 'education 1',
        currentlyPursuingEdu: '',
        eduErrorMessageWork: '',
        eduErrorWork: ''
    })

    const [linkData, setLinkData] = useState({
        linkTitle: '',
        linkHref: '',
        linkError: '',
        linkErrorMessage: '',
    })

    const [workExprience, setWorkExprience] = useState([])
    const [eduExprience, setEduExprience] = useState([])
    const [otherLinks, setOtherLink] = useState([])

    const { title, description, currentlyPursuing, workLink, techValue } = work

    const { eduTitle, eduDescription, currentlyPursuingEdu } = education

    const { githubProfile, stackOverFlowProfile, linkTitle, linkHref } = linkData

    const { error, errorMessage } = values

    const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
    const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));

    const [openModelGit, setOpenModelGit] = useState(false)

    const location = useLocation();

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getQueryParams = () => {
            const searchParams = new URLSearchParams(location.search);
            const githubId = searchParams.get('githubId');
            const accessToken = searchParams.get('accessToken');
            return { userId: data.user._id, githubId, accessToken };
        };

        const userData = getQueryParams();
        if (userData.accessToken === null || userData.githubId === null) {
        } else {
            postGitCreds(userData).then(res => {
                console.log(res)
            })
        }
        navigate('/dashboard', { replace: true });
    }, [location.search]);

    const [gitTokenAvailable, setGitTokenAvailable] = useState(false)

    useEffect(() => {
        getRepos(data.user._id).then(response => {
            if (response.success) {
                setGitTokenAvailable(true)
                setGitRepos([...response.privateRepos, ...response.publicRepos])
                setLoading(false)
            } else {
                setGitTokenAvailable(false)
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const [selectedRepository, setSelectedRepository] = useState(null);
    const [gitRepos, setGitRepos] = useState([])

    const handleChangeWork = name => event => {
        setWork({ ...work, errorWork: '', errorMessageWork: '', [name]: event.target.value });
    };

    const handleChangeEdu = name => event => {
        setEducation({ ...education, eduError: '', eduErrorMessage: '', [name]: event.target.value });
    };

    const handleChangeLink = name => event => {
        setLinkData({ ...linkData, linkError: '', linkErrorMessage: '', [name]: event.target.value })
    }

    const handleCurrentlyPursuingEdu = (event) => {
        setEducation({ ...education, currentlyPursuingEdu: event.target.value, endDate: "Currently Working" })
    }

    const onAppendEduArray = (newInput) => {
        setEduExprience(eduExprience => [...eduExprience, newInput])
        setEduDisplay(true)
        setEducation({
            eduTitle: 'test',
            eduDescription: 'test',
            eduErrorMessageWork: '',
            eduErrorWork: '',
            currentlyPursuingEdu: 'false'
        })
    }

    const updateWorkTech = (data) => {
        setTechUsed(techUsed => [...techUsed, data])
        setWork({ ...work, errorWork: '', errorMessageWork: '', techValue: '' });

    }
    const handleDeleteTech = (ele) => {
        setTechUsed(techUsed.filter(e => e !== ele))
    }

    const onAppendWorkArray = (newInput) => {
        setWorkExprience(workExprience => [...workExprience, newInput])
        setWorkDisplay(true)
        setWork({
            ...work,
            title: '',
            description: '',
            workLink: '',
            repoName: '',
            repoLink: '',
            currentlyPursuing: 'false',
            techValue: '',
            errorMessageWork: '',
            errorWork: ''
        })
    }

    const onAppendLinkArray = (newInput) => {
        setOtherLink(otherLinks => [...otherLinks, newInput])
        setDisplayOtherLink(true)
    }

    const deleteWork = (i) => {
        setWorkExprience(workExprience => {
            return workExprience.filter((_, index) => index !== i)
        })
    }
    const deleteEdu = (i) => {
        setEduExprience(eduExprience => {
            return eduExprience.filter((_, index) => index !== i)
        })
    }
    const deleteLink = (i) => {
        setOtherLink(otherLinks => {
            return otherLinks.filter((_, index) => index !== i)
        })
    }
    const deleteTech = (i) => {
        setWork({ ...work, techUsed: techUsed.filter(e => e !== i) })
    }

    const updateWork = (w) => {
        setWork(w)
        setSelectedRepository({ name: w.repoName, link: w.repoLink })
        setTechUsed(w.techUsed)
        setStartDateValue(w.startDate)
        setEndDateValue(w.endDate)
    }

    const updateEdu = (w) => {
        setEducation(w)
        setStartDateValue(w.startDate)
        setEndDateValue(w.endDate)
    }

    const updateLink = (w) => {
        setLinkData(w)
    }

    const onUpdateWorkArray = (index, newInput) => {
        console.log(index, newInput)
        let dummyArray = [...workExprience]
        dummyArray[index] = newInput
        setWorkExprience(dummyArray)
        setTechUsed([])
        setIsUpdate(false)
    }

    const onUpdateEduArray = (index, newInput) => {
        let dummyArray = [...eduExprience]
        dummyArray[index] = newInput
        setEduExprience(dummyArray)
        setIsUpdate(false)
    }


    const onUpdateLinkArray = (index, newInput) => {
        console.log(index)
        let dummyArray = [...otherLinks]
        dummyArray[index] = newInput
        setOtherLink(dummyArray)
        setIsUpdate(false)
    }

    const conformationCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any work exprience`) === true) {
                setWorkFormDisplay(false)
                setEduFormDisplay(true)
                setWorkDisplay(false)
            } else {
                setWorkFormDisplay(true)
                setOtherLinkForm(false)
                setEduFormDisplay(false)
            }
        } else {
            setWorkFormDisplay(false)
            setEduFormDisplay(true)
            setWorkDisplay(false)
        }
    }
    const conformationEduCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any education exprience`) === true) {
                setWorkFormDisplay(false)
                setEduFormDisplay(false)
                setOtherLinkForm(true)
                setEduDisplay(false)
            } else {
                setWorkFormDisplay(false)
                setEduFormDisplay(true)
                setOtherLinkForm(false)
                setEduDisplay(false)
            }
        } else {
            setWorkFormDisplay(false)
            setEduFormDisplay(false)
            setOtherLinkForm(true)
            setEduDisplay(false)
        }
    }
    const conformationLinkCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any other links`) === true) {
                setDisplayViewData(true)
                setEduFormDisplay(false)
                setWorkFormDisplay(false)
                setOtherLinkForm(false)
            } else {
                setDisplayViewData(false)
                setEduFormDisplay(false)
                setWorkFormDisplay(false)
                setOtherLinkForm(true)
            }
        } else {
            setDisplayViewData(true)
            setEduFormDisplay(false)
            setWorkFormDisplay(false)
            setOtherLinkForm(false)
        }
    }
    const handleSubmitProfile = (profileData) => {
        submitProfile(profileData).then(res => {
            if (res.success) {
                const currentUser = JSON.parse(localStorage.getItem('user'));
                const profile = res.profile;
                const updatedUser = {
                    ...currentUser,
                    message: "Your Profile is ready",
                    profileStatus: true,
                    profile: profile
                };

                localStorage.setItem('user', JSON.stringify(updatedUser));
                console.log(profile)
                console.log(updatedUser)
                setStateUser(updatedUser);
            } else {
                console.log(res)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const handleLogin = () => {
        window.location.href = gitUrl;
    };

    const handleLoginGit = () => {
        window.location.href = gitUrl;
    };

    return (
        <AuthNavbar>
            <div className=''>
                {data?.profileStatus === false ? <div style={{ backgroundColor: "wihtesmoke" }} className="card border-0 my-md-5 my-3 mx-md-5 mx-3">
                    <h3 align="center" className='text-danger'>!{data.message}</h3>
                    {displayViewData === false ? <div className='row'>
                        {workFormDisplay === true ?
                            <div className='col-11 col-md-4 p-2 mx-auto'>
                                <h3 className="text-dark">Work Exprience Form</h3>
                                <div className='py-1'>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={title}
                                        label={errorMessage === '' ? "Title" : errorMessage}
                                        onChange={handleChangeWork('title')}
                                        variant="outlined" />
                                </div>
                                <div className='py-1'>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        multiline
                                        maxRows={4}
                                        value={description}
                                        label={errorMessage === '' ? "Description" : errorMessage}
                                        onChange={handleChangeWork('description')}
                                        variant="outlined" />
                                </div>

                                {loading ? <div className="d-flex flex-wrap justify-content-center align-items-center">
                                    <div>
                                        <CircularProgress size={30} />
                                    </div>
                                    <div>
                                        <span className="px-2">Loading Git Tokens</span>
                                    </div>
                                </div> : <div>
                                    {!gitTokenAvailable ? <div align="center" className="pt-2">
                                        <button
                                            className="btn btn-danger btn-sm fw-bold"
                                            onClick={handleLogin}>Integrate Your Github
                                        </button>
                                        <button
                                            className="btn btn-sm btn-outline-primary fw-bold mx-2"
                                            onClick={() => setOpenModelGit(true)}>
                                            ?
                                        </button>
                                        <Modal
                                            open={openModelGit}
                                            onClose={() => setOpenModelGit(false)}
                                        >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                                <div className="p-md-3 p-2 card col-6">
                                                    <p>
                                                        Git Integration
                                                    </p>
                                                    <p>
                                                        include text model for github Integratation
                                                    </p>
                                                    <div align="center">
                                                        <button onClick={() => setOpenModelGit(false)} className="btn col-6 btn-primary">
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div> : <div><div className="d-flex align-items-center">
                                        <div className="my-2 w-100">
                                            <FormControl fullWidth>
                                                <div className="">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={gitRepos.map(repo => ({ name: repo.name, link: repo.url }))}
                                                        getOptionLabel={(option) => option.name}
                                                        value={selectedRepository}
                                                        onChange={(event, newValue) => {
                                                            setSelectedRepository(newValue === '' ? null : newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="Select Repository" />}
                                                    />
                                                    {/* Add error handling if needed */}
                                                </div>
                                            </FormControl>
                                        </div>
                                        <button
                                            className="btn btn-sm btn-danger fs-5 fw-bold m-2"
                                            onClick={() => setOpenModelGit(true)}>
                                            ?
                                        </button>
                                        <Modal
                                            open={openModelGit}
                                            onClose={() => setOpenModelGit(false)}
                                        >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                                <div className="p-md-3 p-2 card col-6">
                                                    <p>
                                                        Git Integration
                                                    </p>
                                                    <p>
                                                        Include text model for github Integratation
                                                    </p>
                                                    <div align="center">
                                                        <button onClick={() => setOpenModelGit(false)} className="btn btn-sm col-6 fw-bold btn-primary">
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                        <small className="fw-bold text-danger">*You have successfully integrated GitHub</small>
                                    </div>}
                                </div>
                                }
                                <div className='py-2'>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={workLink}
                                        label={errorMessage === '' ? "Mention other link available" : errorMessage}
                                        onChange={handleChangeWork('workLink')}
                                        variant="outlined" />
                                </div>
                                <div>
                                    <div className="d-flex justify-content-center align-item-center">
                                        <div className="d-flex justify-content-between align-item-center row px-0 col-12">
                                            <div className="col-12 col-md-6 px-0">
                                                <TextField
                                                    className='my-2 col-12'
                                                    error={errorMessage === "" ? false : true}
                                                    id="custom-css-outlined-input"
                                                    color={error ? "error" : ""}
                                                    value={techValue}
                                                    label={errorMessage === '' ? "Tech Used" : errorMessage}
                                                    onChange={handleChangeWork('techValue')}
                                                    variant="outlined" />
                                            </div>
                                            <button onClick={() => updateWorkTech(techValue)} className="col-12 col-md-3 btn btn-sm my-3 fw-bold fs-6 btn-primary rounded-pill">
                                                Add tech
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around">
                                        <div className="col-12 card bg-transparent border-primary">
                                            <div className="col-12 d-flex flex-wrap ">
                                                {techUsed.map((p, i) => (
                                                    <div key={i} className="bg-primary rounded-pill p-1 px-2 m-2">
                                                        <span className="px-2 fw-bold text-white m-0">{p}</span>
                                                        <button className="btn btn-sm rounded-circle btn-danger" onClick={() => handleDeleteTech(p)}>
                                                            <FontAwesomeIcon size="1x" icon={faTrash} /></button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div align="center">
                                    {isUpdate ?
                                        <button className='col-10 my-3 fw-bold btn btn-warning'
                                            onClick={() => onUpdateWorkArray(index, {
                                                title,
                                                description,
                                                workLink,
                                                repoName: selectedRepository === null ? "" : selectedRepository.name,
                                                repoLink: selectedRepository === null ? "" : selectedRepository.link,
                                                startDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                endDate: `${currentlyPursuing === 'true' ? "Currently Working" : `${dayjs(endDateValue).format('MM/DD/YY')}`}`,
                                                currentlyPursuing,
                                                techUsed
                                            })}>
                                            Update
                                        </button> :
                                        <div className='row justify-content-between'>
                                            <button className='col-12 col-lg-7 m-1 fw-bold rounded-pill fs-6 btn btn-primary btn-sm'
                                                onClick={() => onAppendWorkArray({
                                                    title,
                                                    description,
                                                    workLink,
                                                    repoName: selectedRepository === null ? "" : selectedRepository.name,
                                                    repoLink: selectedRepository === null ? "" : selectedRepository.link,
                                                    startDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                    endDate: `${currentlyPursuing === 'true' ? "Currently Working" : `${dayjs(endDateValue).format('MM/DD/YY')}`}`,
                                                    currentlyPursuing,
                                                    techUsed
                                                })}>
                                                Add Work Exprience
                                            </button>
                                            <button className='col-12 col-lg-3 m-1 fw-bold rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    conformationCheck(workExprience)
                                                }}>{workExprience.length === 0 ? <span>Skip</span> : <span>Next</span>}
                                            </button>
                                        </div>}
                                </div>
                            </div> : null}

                        {/* Education form */}

                        {eduFormDisplay === true ?
                            <div className='col-10 col-md-4 p-3 mx-auto'>
                                <h3 className="text-dark">Education Form</h3>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={eduTitle}
                                        label={errorMessage === '' ? "Title" : errorMessage}
                                        onChange={handleChangeEdu('eduTitle')}
                                        variant="outlined" />
                                </div>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        multiline
                                        maxRows={4}
                                        value={eduDescription}
                                        label={errorMessage === '' ? "Description" : errorMessage}
                                        onChange={handleChangeEdu('eduDescription')}
                                        variant="outlined" />
                                </div>
                                <div>
                                    <RadioGroup
                                        value={currentlyPursuingEdu}
                                        onChange={handleCurrentlyPursuingEdu}
                                    >
                                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                                            <div className="col-md-5 col-5">
                                                <FormControlLabel className="text-dark" value={true} control={<Radio />} label="Currently Pursuing" />
                                            </div>
                                            <div className="col-md-5 col-5">
                                                <FormControlLabel className="text-dark" value={false} control={<Radio />} label="Currently Not Pursuing" />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>
                                <div align="center" className='py-3'>
                                    {isUpdate ?
                                        <button className='col-10 my-3 btn btn-warning'
                                            onClick={() => onUpdateEduArray(index, {
                                                eduTitle,
                                                eduDescription,
                                                eduStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                eduEndDate: `${currentlyPursuingEdu === 'true' ? "Currently Pursuing" : `${dayjs(startDateValue).format('MM/DD/YY')}`}`,
                                                currentlyPursuingEdu
                                            })}>
                                            Update
                                        </button> :
                                        <div className='row justify-content-between'>
                                            <button className='order-lg-2 order-1 fw-bold col-12 col-lg-5 my-1 rounded-pill fs-6 btn btn-primary btn-sm'
                                                onClick={() => onAppendEduArray({
                                                    eduTitle,
                                                    eduDescription,
                                                    eduStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                    eduEndDate: `${currentlyPursuingEdu === 'true' ? "Currently Pursuing" : `${dayjs(startDateValue).format('MM/DD/YY')}`}`,
                                                    currentlyPursuingEdu
                                                })}>
                                                Add Education
                                            </button>
                                            <button className='order-lg-1 order-3 col-12 fw-bold col-lg-3 my-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    setWorkFormDisplay(true)
                                                    setOtherLinkForm(false)
                                                    setEduFormDisplay(false)
                                                }}>BACK</button>
                                            <button className='order-lg-3 order-2 col-12 fw-bold col-lg-3 my-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    conformationEduCheck(eduExprience)
                                                }}>Next</button></div>}
                                </div>
                            </div> : null}
                        {otherLinksForm === true ?
                            <div className='col-10 col-md-4 p-3 mx-auto'>
                                <Collapse in={true}>
                                    <div className=''>
                                        <TextField
                                            style={{ width: '100%', textTransform: 'capitalize !important' }}
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="outlined-basic"
                                            value={linkTitle}
                                            label={errorMessage === '' ? "Title" : errorMessage}
                                            onChange={handleChangeLink('linkTitle')}
                                            variant="outlined" />
                                    </div>
                                    <div className=''>
                                        <TextField
                                            style={{ width: '100%' }}
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="outlined-basic"
                                            value={linkHref}
                                            label={errorMessage === '' ? "Link" : errorMessage}
                                            onChange={handleChangeLink('linkHref')}
                                            variant="outlined" />
                                    </div>
                                    <div align="center" className='py-3'>
                                        {isUpdate ?
                                            <button className='col-10 my-3 btn btn-warning'
                                                onClick={() => onUpdateLinkArray(index, {
                                                    linkTitle,
                                                    linkHref
                                                })}>
                                                Update
                                            </button> :
                                            <div className='row justify-content-between'>
                                                <button className='order-lg-2 order-1 fw-bold col-12 col-lg-5 my-1 rounded-pill fs-6 btn btn-primary btn-sm'
                                                    onClick={() => onAppendLinkArray({
                                                        linkTitle,
                                                        linkHref
                                                    })}>
                                                    Add Link
                                                </button>
                                                <button className='order-lg-1 order-3 fw-bold col-12 col-lg-3 my-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                    onClick={() => {
                                                        setWorkFormDisplay(false)
                                                        setOtherLinkForm(false)
                                                        setEduFormDisplay(true)
                                                    }}>BACK</button>
                                                <button className='order-lg-3 order-2 fw-bold col-12 col-lg-3 my-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                    onClick={() => {
                                                        conformationLinkCheck(eduExprience)
                                                    }}>View Data</button></div>}
                                    </div>
                                </Collapse>
                            </div> : null}
                        <div className='text-dark col-11 col-lg-4 px-0 mx-auto'>
                            <Collapse className='' in={workDisplay}>
                                <div align="center"><h3>Work exprience you added</h3></div>
                                <div className='border-start border-3 border-primary shadow-lg'>
                                    {workExprience.length !== 0 ? <div className='py-0'>{workExprience.map((w, i) =>
                                        <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                            <span className='d-flex align-items-center'><h4>{w.title}</h4></span>
                                            <div style={{ height: '10vh', overflow: 'auto' }}><b className="">Description:</b> {w.description}</div><br />
                                            <div>Repo: {w.repoName}</div>
                                            <div>Tech Used: {w.techUsed.map((t, i) => <span key={i} className='bg-primary rounded-pill mx-2 px-2 p-1'>{t}</span>)}</div>
                                            <hr className='bg-dark p-0 m-1' />
                                            <div className='d-flex justify-content-around py-2'>
                                                <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                    onClick={() => {
                                                        setIsUpdate(true)
                                                        setIndex(i)
                                                        updateWork(w)
                                                    }}>Update</button>
                                                <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                    onClick={() => deleteWork(i)}>Delete</button>
                                            </div>
                                        </div>)}</div> : null}
                                </div>
                                <div align="center" className='py-3'>
                                    <button className='btn btn-dark rounded-pill'
                                        onClick={() => setWorkDisplay(false)}>
                                        Close
                                    </button>
                                </div>
                            </Collapse>
                            <Collapse className='' in={eduDisplay}>
                                <div align="center"><h3>Education you added</h3></div>
                                <div className='border-start border-3 border-primary shadow-lg'>
                                    {eduExprience.length !== 0 ? <div className='py-0'>{eduExprience.map((w, i) =>
                                        <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                            <span className='d-flex align-items-center'><h4>{w.eduTitle}</h4></span>
                                            <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Description:</b> {w.eduDescription}</div><br />
                                            <hr className='bg-dark p-0 m-1' />
                                            <div className='d-flex justify-content-around py-2'>
                                                <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                    onClick={() => {
                                                        setIsUpdate(true)
                                                        setIndex(i)
                                                        updateEdu(w)
                                                    }}>Update</button>
                                                <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                    onClick={() => deleteEdu(i)}>Delete</button>
                                            </div>
                                        </div>)}</div> : null}
                                </div>
                                <div align="center" className='py-3'>
                                    <button className='btn btn-dark rounded-pill'
                                        onClick={() => setEduDisplay(false)}>
                                        Close
                                    </button>
                                </div>
                            </Collapse>
                            <Collapse className='' in={displayOtherLink}>
                                <div align="center"><h3>Other Links</h3></div>
                                <div className='border-start border-3 border-primary shadow-lg'>
                                    {otherLinks.length !== 0 ? <div className='py-0'>{otherLinks.map((w, i) =>
                                        <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                            <span className='d-flex align-items-center'><h4>{w.linkTitle}</h4></span>
                                            <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Link:</b> {w.linkHref}</div><br />
                                            <hr className='bg-dark p-0 m-1' />
                                            <div className='d-flex justify-content-around py-2'>
                                                <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                    onClick={() => {
                                                        setIsUpdate(true)
                                                        setIndex(i)
                                                        updateLink(w)
                                                    }}>Update</button>
                                                <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                    onClick={() => deleteLink(i)}>Delete</button>
                                            </div>
                                        </div>)}</div> : null}
                                </div>
                                <div align="center" className='py-3'>
                                    <button className='btn btn-dark rounded-pill'
                                        onClick={() => setDisplayOtherLink(false)}>
                                        Close
                                    </button>
                                </div>
                            </Collapse>
                            <Collapse className='d-lg-block d-none' in={!workDisplay && !eduDisplay && !displayOtherLink}>
                                <div align='center' className='py-2'>
                                    <h3>View Your</h3>
                                </div>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className='px-2'>
                                        <button className='m-auto btn btn col-12 rounded-pill btn-outline-success'
                                            onClick={() => setWorkDisplay(true)}>
                                            {`Work - ${workExprience.length}`}
                                        </button>
                                    </div>
                                    <div className='px-2'>
                                        <button className='m-auto btn btn col-12 rounded-pill btn-outline-success'
                                            onClick={() => setEduDisplay(true)}>
                                            {`Education - ${eduExprience.length}`}
                                        </button>
                                    </div>
                                    <div className='px-2'>
                                        <button className='m-auto btn btn col-12 rounded-pill btn-outline-success'
                                            onClick={() => setDisplayOtherLink(true)}>
                                            {`Links - ${otherLinks.length}`}
                                        </button>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div> : <div className='text-dark'>
                        <div align="center" className='py-3'>
                            <button className='btn btn-dark rounded-pill'
                                onClick={() => {
                                    setWorkFormDisplay(true)
                                    setDisplayViewData(false)
                                }}>
                                Move back to forms
                            </button>
                            <button className='btn btn-dark rounded-pill'
                                onClick={() =>
                                    handleSubmitProfile({
                                        userId: data.user._id,
                                        workExprience: workExprience,
                                        educationExprience: eduExprience,
                                        otherLinks: otherLinks,
                                        githubProfile: githubProfile,
                                        stackOverFlowProfile: stackOverFlowProfile
                                    })}>
                                Upload Data
                            </button>
                        </div>
                        <div className='row'>
                            <div className='col-lg p-3'>
                                <Collapse in={!wvd}>
                                    <button
                                        onClick={() => {
                                            setwvd(true)
                                            setevd(false)
                                            setlvd(false)
                                        }}
                                        className='btn col-12 rounded-pill btn-outline-success'>Open Work exprience</button>
                                </Collapse>
                                <Collapse in={wvd}>
                                    <div align="center" className='d-flex justify-content-around align-items-center'><h3>Work exprience you added</h3>
                                        <button
                                            onClick={() => setwvd(false)}
                                            className='btn my-3 rounded-pill btn-outline-danger'>Close</button></div>
                                    <div className='border-start border-3 border-primary shadow' style={{ height: '50vh', overflowY: 'scroll' }}>
                                        {workExprience.length !== 0 ? <div className='py-0'>{workExprience.map((w, i) =>
                                            <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                                <span className='d-flex align-items-center'><h4>{w.title}</h4><span className='px-3'> {w.startDate} - {w.endDate}</span></span>
                                                <div style={{ height: '10vh', overflowY: 'auto' }}><b className="">Description:</b> {w.description}</div><br />
                                                <div>REPOS: {w.repoName} {w.repoLink}</div>
                                                <div style={{ wordWrap: 'break-word' }}>Tech Used: {w.techUsed.map((t, i) => <span key={i} className='bg-primary rounded-pill mx-2 px-2 p-1' style={{ wordWrap: 'break-word' }}>{t}</span>)}</div>
                                                <hr className='bg-dark p-0 m-1' />
                                                <div className='d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setWorkFormDisplay(true)
                                                            setWorkDisplay(true)
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateWork(w)
                                                            setDisplayViewData(false)
                                                        }}>Update</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteWork(i)}>Delete</button>
                                                </div>
                                            </div>)}</div> : null}
                                    </div>
                                    {/* <button
                                onClick={() => setwvd(false)}
                                className='btn my-3 shadow-lg col-12 rounded-pill btn-outline-danger'>Close</button> */}
                                </Collapse>
                            </div>
                            <div className='d-lg-none d-block'>
                                <hr className='bg-dark' />
                            </div>
                            <div className='col-lg p-3'>
                                <Collapse in={!evd}>
                                    <button
                                        onClick={() => {
                                            setwvd(false)
                                            setevd(true)
                                            setlvd(false)
                                        }}
                                        className='btn col-12 rounded-pill btn-outline-success'>Open Education exprience</button>
                                </Collapse>
                                <Collapse in={evd}>
                                    <div align="center" className='d-flex justify-content-around align-items-center'><h3>Education exprience you added</h3>
                                        <button
                                            onClick={() => setevd(false)}
                                            className='btn my-3 rounded-pill btn-outline-danger'>Close</button></div>
                                    <div className='border-start border-3 border-primary shadow' style={{ height: '50vh', overflowY: 'scroll' }}>
                                        {eduExprience.length !== 0 ? <div className='py-0'>{eduExprience.map((w, i) =>
                                            <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                                <span className='d-flex align-items-center'><h4>{w.eduTitle}</h4><span className='px-3'> {w.eduStartDate} - {w.eduEndDate}</span></span>
                                                <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Description:</b> {w.eduDescription}</div><br />
                                                <hr className='bg-dark p-0 m-1' />
                                                <div className='d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setEduFormDisplay(true)
                                                            setEduDisplay(true)
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateEdu(w)
                                                            setDisplayViewData(false)
                                                        }}>Update</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteEdu(i)}>Delete</button>
                                                </div>
                                            </div>)}
                                        </div> : null}
                                    </div>
                                </Collapse>
                            </div>
                            <div className='d-lg-none d-block'>
                                <hr className='bg-dark' />
                            </div>
                            <div className='col-lg p-3'>
                                <Collapse in={!lvd}>
                                    <button
                                        onClick={() => {
                                            setwvd(false)
                                            setevd(false)
                                            setlvd(true)
                                        }}
                                        className='btn col-12 rounded-pill btn-outline-success'>Open Links added</button>
                                </Collapse>
                                <Collapse in={lvd}>
                                    <div align="center" className='d-flex justify-content-around align-items-center'><h3>Other links you added</h3>
                                        <button
                                            onClick={() => setlvd(false)}
                                            className='btn my-3 rounded-pill btn-outline-danger'>Close</button></div>
                                    <div className='border-start border-3 border-primary shadow' style={{ height: '50vh', overflowY: 'scroll' }}>
                                        {otherLinks.length !== 0 ? <div className='py-0'>{otherLinks.map((w, i) =>
                                            <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                                <span className='d-flex align-items-center'><h4>{w.linkTitle}</h4></span>
                                                <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Link:</b> {w.linkHref}</div><br />
                                                <hr className='bg-dark p-0 m-1' />
                                                <div className='d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setOtherLinkForm(true)
                                                            setDisplayOtherLink(true)
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateLink(w)
                                                            setDisplayViewData(false)
                                                        }}>Update</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteLink(i)}>Delete</button>
                                                </div>
                                            </div>)}</div> : null}
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>}</div> :
                    // this is the logged in section
                    <div className="container-fluid">
                        <div className="row d-flex flex-wrap justify-content-around container-fluid">
                            <div className="col-12 col-md-3 py-5" style={{
                                position: "sticky", top: 0
                            }}>
                                <div className=" d-flex justify-content-end card bg-transparent p-2 rounded-lg border-0">
                                    <div className="d-flex flex-column flex-wrap justify-content-end">
                                        <div className="py-1">
                                            <Link to="" state={data}>
                                                <button
                                                    onClick={() => setDisplayProfile(true)}
                                                    className="btn fw-bold btn-primary rounded-pill col-12">
                                                    My Profile
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="py-1">
                                            <Link to='teams-joined' state={data.profile.joined}>
                                                <button
                                                    onClick={() => setDisplayProfile(false)}
                                                    className="btn fw-bold btn-primary rounded-pill col-12">
                                                    Team Joined
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="py-1">
                                            <Link to='team-requested' state={data.profile.pendingRequests}>
                                                <button
                                                    onClick={() => setDisplayProfile(false)}
                                                    className="btn fw-bold btn-primary rounded-pill col-12">
                                                    Team Requested
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="py-1">
                                            <Link to='teams-removed' state={data.profile.teamRemoved}>
                                                <button
                                                    onClick={() => setDisplayProfile(false)}
                                                    className="btn fw-bold btn-primary rounded-pill col-12">
                                                    Team Removed
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-9 py-5" style={{
                                height: '90vh',
                                overflowY: 'auto'
                            }}>
                                <div className="card bg-transparent rounded-lg d-flex justify-content-center align-items-center border-0 p-2">
                                    <div className={`col-md-9 col-12 bg-white ${displayProfile ? 'd-block' : 'd-none'}`}>
                                        <div className="">
                                            <span className="fs-2 m-0"><b className="m-0">{data?.user?.firstName} {data?.user?.lastName}</b></span>
                                        </div>
                                        <div>
                                            <span className="fs-4 fw-bold">Rank Range: <span className="text-primary">{data?.profile?.rankId?.rankRange}</span></span>
                                        </div>
                                        <div className="">
                                            <button onClick={() => handleLoginGit()} className="btn btn-sm btn-danger fw-bold">
                                                Integrate GitHub
                                            </button>
                                        </div>
                                        <div>
                                            <div>
                                                <div className="py-3">
                                                    <div className='shadow py-3'>
                                                        <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Work exprience you added</h3></div>
                                                        {data?.profile?.workExprience?.length !== 0 ? <div className='py-2'>{data?.profile?.workExprience?.map((w, i) =>
                                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                <span className='d-flex align-items-center'><h4>{w.title}</h4></span>
                                                                <div><b className=""></b>{w.description}</div><br />
                                                                <div className="fw-bold" style={{ wordWrap: 'break-word' }}>Tech Used: {w.techUsed.map((t, i) => <span key={i} className='bg-primary rounded-pill mx-2 px-2 p-1 fw-bold text-white' style={{ wordWrap: 'break-word' }}>{t}</span>)}</div>
                                                                {data?.profile?.workExprience?.length - 1 === i ? null : <hr />}
                                                            </div>)}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="py-3">
                                                    <div className='shadow py-3'>
                                                        <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Education exprience you added</h3></div>
                                                        {data?.profile?.educationExprience?.length !== 0 ? <div className='py-0'>{data?.profile?.educationExprience?.map((w, i) =>
                                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                <span className='d-flex align-items-center'><h4>{w.eduTitle}</h4><span className='px-3'> {w.eduStartDate} - {w.eduEndDate}</span></span>
                                                                <div><b className=""></b>{w.eduDescription}</div><br />
                                                                {data?.profile?.educationExprience?.length - 1 === i ? null : <hr />}
                                                            </div>)}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="py-3">
                                                    <div className='shadow py-3'>
                                                        <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Other links you added</h3></div>
                                                        {data?.profile?.otherLinks?.length !== 0 ? <div className='py-0'>{data?.profile?.otherLinks?.map((w, i) =>
                                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                <span className='d-flex align-items-center'><h4>{w.linkTitle}</h4></span>
                                                                <div><b className="">Link:</b> {w.linkHref}</div>
                                                                {data?.profile?.otherLinks?.length - 1 === i ? null : <hr />}
                                                            </div>)}</div> : <div className="py-3 text-center">
                                                            <h4>No Links Added</h4>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className="py-5">
                                                    <div>
                                                        <h4 className="fw-bold">Reviews</h4>
                                                    </div>
                                                    {data?.profile?.expertsScore?.reviews.map((e, i) => (
                                                        <div key={i} className="">
                                                            <div className="card bg-transparent border-primary p-2 my-2" >
                                                                <div className="">
                                                                    <h4>
                                                                        {e?.userId?.firstName} {e?.userId?.lastName}
                                                                    </h4>
                                                                    <div>
                                                                        <span>
                                                                            <b>Remarks:</b> {e.reviewDesc}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </AuthNavbar>
    )
}

export default DashBoard

