import { TextField } from '@mui/material';
import students from '../authFlow/assets/freelancer_page.png'
import Navbar from '../authFlow/Navbar';
import { useAuthStore } from '../store/store';
import { fetchEmail } from '../actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Footer from './Footer';

const Freelancers = () => {

    const userData = useAuthStore(state => state.user)
    const navigate = useNavigate()
    const setStateUser = useAuthStore(state => state.setUser)

    const [values, setValues] = useState({
        email: '',
        error: '',
        errorMessage: ''
    })

    const { email, errorMessage } = values

    const emailRegex = /\S+@\S+\.\S+/;

    const handleChange = name => event => {
        setValues({ ...values, error: '', errorMessage: '', [name]: event.target.value });
    };

    const handleSubmit = (email) => {
        if (emailRegex.test(email)) {
            fetchEmail({ email: email }).then(res => {
                if (res.userExist === false) {
                    navigate('/register', { state: { sessionId: res.sessionId } })
                }
                if (res.userExist === true) {
                    navigate('/login', { state: { sessionId: res.sessionId } })
                }
            })
        } else {
            setValues({ ...values, errorMessage: "Please enter a valid Email" })
        }
    }

    const onClickLogout = () => {
        setStateUser(null)
        localStorage.removeItem('user')
    }

    return (
        <>
            <div className='py-2 sticky-top'>
                <Navbar />
            </div>
            <div className="container-fluid d-flex flex-wrap justify-content-center py-5">
                <div className="col-11">
                    <div className='fs-1 d-lg-block d-none pb-3 fw-bold text-center' data-aos="fade-up" data-aos-delay="400">
                        Freelancers
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="col-lg-5 px-md-5">
                            <img src={students} className="img-fluid" alt="devSemble Logo" data-aos="fade-right" data-aos-delay="500" />
                            <div className='fs-1 d-lg-none d-block pb-3 fw-bold text-center' data-aos="fade-up" data-aos-delay="00">
                                Freelancers
                            </div>
                        </div>
                        <div className="col-lg-6 px-md-5">
                            <div className="fs-3">
                                <div className='py-2' data-aos="fade-up" data-aos-delay="200">For freelancers, <span className='text-primary fw-bolder'><b>devSemble</b></span> is the ultimate platform to find exciting projects and grow your career.
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                    Create a detailed profile, link your GitHub repositories, and showcase your work to project listers.
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="400">
                                    Benefit from our automated profile review and ranking system to enhance your visibility and get matched with suitable projects.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center'>
                <h2>Coming Soon!!!</h2>
                <Link to={'/auth'}>
                    <button className='btn btn-primary fw-bold rounded-pill my-3'>
                        Get Started
                    </button>
                </Link>
            </div>
            <div className='pt-5'>
                <Footer />
            </div>
        </>
    )
}

export default Freelancers