import { Link } from "react-router-dom"
import { useAuthStore } from '../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import { faSignOut } from '@fortawesome/free-solid-svg-icons'

const AuthNavbar = ({ children }) => {

    const setStateUser = useAuthStore(state => state.setUser)
    const userData = useAuthStore(state => state.user)

    const onClickLogout = () => {
        navigate('/auth')
        setStateUser(null)
        localStorage.removeItem('user')
    }

    const navigate = useNavigate()

    return (
        <div>
            <div className="py-2 py-md-2" style={{ backgroundColor: 'transparent' }}>
                {userData === null ?
                    <div >
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="bg-white p-2 col-11 col-lg-10 border-0 rounded-pill d-flex justify-content-around align-items-center"
                                style={{ boxShadow: "0rem 0rem 1rem #0275d8", wordWrap: 'break-word' }}>
                                <div className="mx-auto">
                                    <Link to="/" className="text-decoration-none">
                                        <h2 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>devSemble</h2>
                                    </Link>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ backgroundColor: 'white' }}>
                            {children}
                        </div>
                    </div> :
                    <div>
                        <div className="navbar d-flex justify-content-center align-items-center">
                            <div className="p-2 col-11 col-lg-10 border-0 rounded-pill d-flex justify-content-between align-items-center"
                                style={{ boxShadow: '0 0 1rem #0275d8', wordWrap: 'break-word' }}>
                                <div className="px-3">
                                    <h3 className="m-0 mx-auto" style={{ fontWeight: 'bolder' }}>{userData.user.firstName}</h3>
                                </div>
                                <div className="mx-auto">
                                    <Link to="/" className="text-decoration-none">
                                        <h2 className="m-0 mx-auto d-none d-md-block" style={{ fontWeight: 'bolder' }}>devSemble</h2>
                                        <h2 className="m-0 mx-auto d-block d-md-none" style={{ fontWeight: 'bolder' }}>DS!</h2>
                                    </Link>
                                </div>
                                <div>
                                    <button className='btn px-lg-3 mx-2 d-flex justify-content-center align-items-center rounded-pill px-2 fw-bold btn-danger'
                                        onClick={() => onClickLogout()}>
                                        <FontAwesomeIcon icon={faSignOut} />
                                        <span className="px-2 d-none d-md-block">Log-Out</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ backgroundColor: 'transparent' }}>
                            {children}
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default AuthNavbar