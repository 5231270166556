import { useNavigate, Link, useParams } from "react-router-dom"
import AuthNavbar from "../AuthNavbar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faCamera, faCameraAlt, faCameraRetro, faCameraRotate, faClose, faFileEdit, faNoteSticky, faPaperPlane, faTrash, faVideoCamera } from '@fortawesome/free-solid-svg-icons'
import { useAuthStore } from '../../store/store';
import { useEffect, useRef, useState } from "react"
import { Autocomplete, CircularProgress, Collapse, FormControl } from "@mui/material"
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { assignGitRepoToProject, fetchRecentChats, getProjectCommits, getSingleProject, updateGitAccess, updateRepoIntegration } from "../../actions/projectActions"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { createTask, getProjectTasks, memberAcceptTask, memberCompleteTask, pushUpgrade, updateTask, markRequirementDone, deleteTask } from "../../actions/taskActions";
import { faCheckCircle, faEdit, faArrowRight, faArrowLeft, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import socket from "../../freeAccess/socket";
import { getRepos } from "../../actions/gitOAuthActions";
import CollaborativeEditor from "../editors/CollaborativeEditor";
import Notes from "../noteUtils/Note";
import VoiceCall from "../voiceChat/VoiceCall";

const CssTextField = styled(TextField)({
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
        },
    },
    '& .MuiChip-label': {
        color: 'black'
    },
    '& .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiChip-deleteIcon .MuiChip-deleteIconMedium .MuiChip-deleteIconColorDefault .MuiChip-deleteIconOutlinedColorDefault': {
        borderColor: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: "black"
    }
});

const StyledSelect = styled(Select)(({ theme }) => ({
    color: 'black',
    label: 'black',
    icon: 'black',
    '& label': {
        color: '#0275d8',
    },
    '& .MuiSvgIcon-root': {
        color: 'black',
    },
}));

const UserSingleProject = () => {

    const navigate = useNavigate()
    // const project_id = useLocation().state
    const [project_id, setProject_id] = useState(useParams().pId)
    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };
    const theme = useTheme();
    const isMdScreen = useMediaQuery('(min-width: 786px)');

    const data = useAuthStore(state => state.user)
    const [refresh, setRefresh] = useState(false)

    const [projectData, setProjectData] = useState({})
    const [projectTasks, setProjectTasks] = useState([])

    const [showAllTasks, setShowAllTasks] = useState(false)
    const [showMyTasks, setShowMyTasks] = useState(true)
    // console.log(projectData?.projectCoHost?._id === data.user._id)
    const [showMobileTask, setShowMobileTasks] = useState(false)
    const [showMobileTeam, setShowMobileTeams] = useState(false)

    const [showTaskForm, setShowTaskForm] = useState(false)

    const [displayUpdatedData, setDisplayUpdatedData] = useState(-1)

    const [myTasks, setMyTasks] = useState([])

    const [isUpdate, setIsUpdate] = useState(false)

    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);

    const [gitRepos, setGitRepos] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRepository, setSelectedRepository] = useState('')
    const [isTeamsVisible, setIsTeamsVisible] = useState(true);

    const handleTeamsToggle = () => {
        setIsTeamsVisible(!isTeamsVisible);
    };
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    const [gitIntegrationStatus, setGitIntegrationStatus] = useState('pending');

    const [formData, setFormData] = useState({
        tId: '',
        taskTitle: "Some Task",
        taskDescription: "Desciption for the task",
        taskStartDate: '',
        taskEndDate: '',
        projectId: project_id,
        assignedBy: data.user._id,
        taskStatus: false,
        assignedTo: '',
        error: '',
        errorMessage: '',
    })

    const {
        tId,
        taskTitle,
        taskDescription,
        taskEndDate,
        taskStartDate,
        projectId,
        taskStatus,
        error,
        errorMessage,
        assignedBy,
        assignedTo
    } = formData

    const [modifiedData, setModifiedData] = useState({
        requirement: '',
        updatedBy: data.user._id,
        errorModified: '',
        errorMessageModified: ''
    })

    const { requirement, updatedBy, errorModified, errorMessageModified } = modifiedData

    const [openModifiedForm, setOpenModifiedForm] = useState(false)

    const handleModifiedChange = name => event => {
        setModifiedData({ ...modifiedData, errorModified: '', errorMessageModified: '', [name]: event.target.value })
    }

    const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
    const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));
    const [authorizedGitAccess, setAuthorizedGitAccess] = useState('')
    const [gitAccessCollapse, setGitAccessCollapse] = useState(false)
    const handleChangeStartDate = (newValue) => {
        // setWork({ ...work, errorWork: '', errorMessageWork: '', startDate: dayjs(newValue).format('MM/DD/YY') });
        setStartDateValue(dayjs(newValue).format('MM/DD/YY'))
    };

    const handleChangeEndDate = (newValue) => {
        // setWork({ ...work, errorWork: '', errorMessageWork: '', endDate: dayjs(newValue).format('MM/DD/YY') });
        setEndDateValue(dayjs(newValue).format('MM/DD/YY'))
    };

    const handleChange = name => event => {
        setFormData({ ...formData, error: '', errorMessage: '', [name]: event.target.value })
    }

    useEffect(() => {
        getSingleProject({ projectId: project_id, gitId: data?.user?.gitTokenId?._id }).then(res => {
            setProjectData(res.response)
            // console.log(data?.user?.gitTokenId?._id)
        }).catch(err => {
            console.log(err)
        })
        getProjectTasks(project_id).then(res => {
            setProjectTasks(res.reverse())
            setMyTasks(res.filter(task => task.assignedTo && task.assignedTo._id === data.user._id))
        }).catch(err => {
            console.log(err)
        })
    }, [refresh])

    const handleAcceptTask = (taskId) => {
        memberAcceptTask(taskId).then(res => {
            if (res.status === true) {
                setRefresh(true)
            }
        })
        setRefresh(false)
    }

    const handleCompleteTask = (data) => {
        memberCompleteTask(data).then(res => {
            if (res.status === true) {
                setRefresh(true)
            }
        })
        setRefresh(false)
    }


    const handleSubmit = (taskObj) => {
        if (taskObj.taskEndDate === dayjs(new Date()).format("MM/DD/YY")) {
            return setFormData({ ...formData, error: "dateError", errorMessage: 'Todays date cannot be Uses' })
        }
        if (taskObj.taskTitle === '' || taskObj.taskDescription === '') {
            return setFormData({ ...formData, error: "formError", errorMessage: 'Fields required' })
        }

        if (taskObj.assignedTo === "") {
            return setFormData({ ...formData, error: "memberError", errorMessage: "Select a member to assign task" })
        }
        else {
            createTask(taskObj).then(res => {
                console.log(res)
                if (res.success === true) {
                    setRefresh(true)
                    setShowMyTasks(true)
                    setShowTaskForm(false)
                }
            })
        }
        setRefresh(false)
    }

    const handleUpdate = (taskObj, tId) => {
        if (taskObj.taskEndDate === dayjs(new Date()).format("MM/DD/YY")) {
            return setFormData({ ...formData, error: "dateError", errorMessage: 'Todays date cannot be Uses' })
        }
        if (taskObj.taskTitle === '' || taskObj.taskDescription === '') {
            return setFormData({ ...formData, error: "formError", errorMessage: 'Fields required' })
        }

        if (taskObj.assignedTo === "") {
            return setFormData({ ...formData, error: "memberError", errorMessage: "Select a member to assign task" })
        }
        else {
            updateTask(taskObj, tId).then(res => {
                if (res.success === true) {
                    setRefresh(true)
                    setShowTaskForm(false)
                    setShowMyTasks(true)
                }
            })
        }
        setRefresh(false)
    }

    const handleUpgrade = (taskUpdates, tId) => {
        if (taskUpdates.requirement === '') {
            setModifiedData({ ...modifiedData, errorModified: 'formError', errorMessageModified: 'Field is required' })
        } else {
            pushUpgrade(taskUpdates, tId).then(res => {
                if (res.status === true) {
                    setRefresh(true)
                    setOpenModifiedForm(false)
                    setShowTaskForm(false)
                    setShowMyTasks(true)
                }
            })
        }
    }

    const handleRequirementDone = (data) => {
        markRequirementDone(data).then(res => {
            if (res.status) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }


    useEffect(() => {
        // Perform handshake
        fetchRecentChats(projectId).then(response => {
            setMessages(response.reverse())
        })
        console.log("SOCKET HANDSHAKE INITIALIZED")
        socket.emit('handshake', { data: 'Hello from client' });

        // Handle handshake success
        socket.on('handshakeSuccess', (data) => {
            console.log('SOCKET RESULT:', data);
        });

        socket.on('gitIntegrationStatus', (data) => {
            console.log(data)
            if (data.projectId === project_id) {
                setGitIntegrationStatus(data.message);
                if (data.success) {
                    handleUpdateRepoIntegration()
                }
            }
        });

        socket.emit('joinProject', projectId);
        return () => {
            // Clean up event listeners
            socket.off('handshakeSuccess');
        };
    }, [project_id]);

    useEffect(() => {
        socket.on('privateMessage', (newMessage) => {
            console.log(newMessage)
            setMessages(messages => [...messages, newMessage]);
        });

        return () => {
            socket.off('privateMessage');
        };
    }, [messages]);

    const handleUpdateRepoIntegration = () => {
        updateRepoIntegration(project_id).then(res => {
            console.log(res)
            if (res.success) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const sendMessage = (event) => {
        event.preventDefault()
        if (message.trim()) {
            socket.emit('privateMessage', {
                sender: data.user.firstName,
                projectId: project_id,
                projectName: projectData.projectTitle,
                content: message
            });
            setMessage('');
        }
    };

    const getAuthorizedUserRepos = () => {
        setLoading(true)
        if (data.user._id === projectData?.authorizedGitAccess?._id) {
            console.log("gfuctvybui")
            getRepos(data.user._id).then(response => {
                if (response.success) {
                    console.log("pxrctvyhj90kl[", response)
                    // setGitTokenAvailable(true)
                    setGitRepos([...response.privateRepos, ...response.publicRepos])
                    setLoading(false)
                } else {
                    // setGitTokenAvailable(false)
                    setLoading(false)
                    console.log("CONNOT GET REPOS")
                }
            }).catch(err => {
                console.log(err)
            })
        } else {
            console.log("you are not authorized for git control for this project")
        }
    }

    const addGitRepo = () => {
        assignGitRepoToProject({ projectId: projectData._id, gitData: selectedRepository }).then(res => {
            console.log(res)
            if (res.success) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const handleProjectCommits = () => {
        getProjectCommits({ projectId: projectData._id, gitId: data?.user?.gitTokenId?._id }).then(res => {
            console.log("COMMITS OF REPO", res)
        }).catch(err => {
            console.log('ERROR IN FETCHING COMMITS', err)
        })
    }

    const handleAuthorizedGitAccess = (event) => {
        setAuthorizedGitAccess(event.target.value)
    }

    const handleSubmitGitAccess = () => {
        updateGitAccess({ gitAccess: authorizedGitAccess._id, projectId: projectId }).then(res => {
            console.log(res)
            if (res.success) {
                setRefresh(true)
                setGitAccessCollapse(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = { weekday: 'short', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const handleDeleteTask = (taskId) => {
        deleteTask(taskId).then(res => {
            setRefresh(true)
        }).catch(err => {
            console.log(err)
        })
    }

    const payloadUrlRef = useRef(null);
    const [toastVisible, setToastVisible] = useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setToastVisible(true);
            setTimeout(() => {
                setToastVisible(false);
            }, 10000);
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    const payloadUrl = `https://devsemble.com/api/git-web-hook-test/${project_id}`;

    const [activeCollapse, setActiveCollapse] = useState('tasks');
    const toggleCollapse = (section) => {
        if (activeCollapse === section) {
            setActiveCollapse('tasks');
        } else {
            setActiveCollapse(section);
        }
    };

    return (
        <AuthNavbar>
            <div className="container-fluid bg-light">
                <div className="d-flex flex-wrap justify-content-center alogn-items-center">
                    <div className="col-11 row align-items-center py-2">
                        <div className="col-auto">
                            <Link to={`/dashboard/`}>
                                <button onClick={() => handleBackClick()} className="btn btn-warning fw-bold rounded-pill">
                                    <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">Go Back</span>
                                </button>
                            </Link>
                        </div>
                        <div className="col text-center">
                            <span className="fs-2 fw-bold" >{projectData.projectTitle}</span>
                        </div>

                        {activeCollapse === 'tasks' && (
                            <>
                                <div className="col-auto">
                                    <button
                                        onClick={() => toggleCollapse('notes')}
                                        className={`${activeCollapse === 'notes' ? 'btn btn-info fw-bold rounded-pill' : 'btn btn-info fw-bold rounded-pill'}`}
                                    >
                                        {activeCollapse === 'notes' ?
                                            <>
                                                <span className="pe-lg-3">Close Notes</span>
                                                <FontAwesomeIcon size="1x" icon={faClose} />
                                            </> :
                                            <>
                                                <span className="pe-lg-3">Notes</span>
                                                <FontAwesomeIcon size="1x" icon={faNoteSticky} />
                                            </>
                                        }
                                    </button>
                                </div>

                                <div className="col-auto">
                                    <button
                                        onClick={() => toggleCollapse('vctool')}
                                        className={`${activeCollapse === 'vctool' ? 'btn btn-info fw-bold rounded-pill' : 'btn btn-info fw-bold rounded-pill'}`}
                                    >
                                        {activeCollapse === 'vctool' ?
                                            <>
                                                <span className="pe-lg-3">Close vctool</span>
                                                <FontAwesomeIcon size="1x" icon={faClose} />
                                            </> :
                                            <>
                                                <span className="pe-lg-3">vctool</span>
                                                <FontAwesomeIcon size="1x" icon={faVideoCamera} />
                                            </>
                                        }
                                    </button>
                                </div>

                                <div className="col-auto">
                                    <button
                                        onClick={() => {
                                            toggleCollapse('editor')
                                            setIsTeamsVisible(!isTeamsVisible)
                                        }}
                                        className={`${activeCollapse === 'editor' ? 'btn btn-info fw-bold rounded-pill' : 'btn btn-info fw-bold rounded-pill'}`}
                                    >
                                        {activeCollapse === 'editor' ?
                                            <>
                                                <span className="pe-lg-3">Close Editor</span>
                                                <FontAwesomeIcon size="1x" icon={faClose} />
                                            </> :
                                            <>
                                                <span className="pe-lg-3">Editor</span>
                                                <FontAwesomeIcon size="1x" icon={faFileEdit} />
                                            </>
                                        }
                                    </button>
                                </div>

                            </>
                        )}

                        {/* More collapsible buttons can be added here following the same logic */}

                        {/* Conditional close button if other collapses are open (only show "Close" for the current open section) */}
                        {activeCollapse !== 'tasks' && (
                            <div className="col-auto">
                                <button
                                    onClick={() => {
                                        toggleCollapse(activeCollapse)
                                        setIsTeamsVisible(true)
                                    }}
                                    className="btn btn-danger fw-bold rounded-pill"
                                >
                                    <span className="pe-lg-3">Close {activeCollapse}</span>
                                    <FontAwesomeIcon size="1x" icon={faClose} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-evenly align-items-center py-2" style={{
                    height: '80vh'
                }}>
                    {/* TEAM OVERVIEW SECTION */}
                    <div className={`teams-div shadow ${isMdScreen ? 'h-100 col-md-2 col-12 card bg-transparent border-0' : 'col-12 p-2 my-1 card border-0 bg-transparent rounded-lg'} ${isTeamsVisible ? 'visible' : 'collapsed'}`} style={{ overflowY: "scroll" }}>
                        {!isMdScreen ? <div className="d-md-none d-block">
                            {showMobileTeam ?
                                <button className="btn btn-sm col-12 btn-danger fw-bold" onClick={() => setShowMobileTeams(false)}>
                                    Close Team
                                </button> :
                                <button className="btn btn-sm col-12 btn-success fw-bold" onClick={() => setShowMobileTeams(true)}>
                                    Show Team
                                </button>
                            }
                        </div> : null
                        }
                        <Collapse in={isMdScreen || showMobileTeam} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                            <div className="p-2">
                                {/* {data.user._id === projectData?.projectCoHost?._id ?
                                    <Link to={`/advanced-dashboard/manage-projects/manage-single-project/${projectData._id}`}>
                                        <button className="btn col-12 fw-bold btn-primary rounded">
                                            Manage Team
                                        </button>
                                    </Link> : null} */}
                                <div className="fs-4 p-2 my-3 fw-bold card  border-primary">
                                    <span>Project Host: </span>
                                    <div className="card bg-transparent border-0">
                                        <span>{projectData?.projectHost?.firstName}</span>
                                    </div>
                                </div>
                                <div className="fs-4 p-2 my-3 fw-bold card  border-primary">
                                    <span>Project CoHost: </span>
                                    <div className="card bg-transparent border-0">
                                        <span>{projectData?.projectCoHost?.firstName}</span>
                                    </div>
                                </div>
                                {data.user._id === projectData?.projectCoHost?._id ?

                                    <div className="card border-secondary p-2">
                                        {!projectData?.authorizedGitAccess ? <div>
                                            <button onClick={() => setGitAccessCollapse(!gitAccessCollapse)} className="col-12 btn btn-sm fw-bold btn-primary">
                                                {!gitAccessCollapse ? 'Assign Git Master Control To' : 'Close'}
                                            </button>
                                        </div> : <><div className="d-flex justify-content-between align-items-start">
                                            <div className="">
                                                <div className=" fw-bold fs-6">Git Access assigned To:</div>
                                                <div className=" fw-bold fs-5">
                                                    {projectData?.authorizedGitAccess?.firstName} {projectData?.authorizedGitAccess?.lastName}
                                                </div>
                                            </div>
                                            <button onClick={() => setGitAccessCollapse(!gitAccessCollapse)} className="btn btn-sm btn-danger fw-bold" style={{ fontSize: 12 }}>
                                                {gitAccessCollapse ? 'Close' : 'Change'}
                                            </button>
                                        </div>
                                            <hr />
                                            <div className="fw-bold">
                                                <div>Repo Name: <span><a target="_blank" style={{ textDecoration: 'none' }} href={`${projectData?.projectGitRepo?.link}`}>{projectData?.projectGitRepo?.name}</a></span></div>
                                            </div></>}
                                        <Collapse in={gitAccessCollapse}>
                                            <span className="text-danger fw-bold" style={{ fontSize: 15 }}>*For git access only the users integrated their account can be selected</span>
                                            <div className="py-2">
                                                <StyledSelect
                                                    className="col-12"
                                                    labelId="my-select-label"
                                                    id="demo-simple-select"
                                                    value={authorizedGitAccess}
                                                    label="Select User to authorize with Git Access"
                                                    variant="outlined"
                                                    onChange={handleAuthorizedGitAccess}
                                                >
                                                    {projectData.projectTeam?.map((p, i) => (
                                                        <MenuItem key={i} disabled={!p.gitTokenId} value={p}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                    ))}
                                                </StyledSelect>
                                            </div>
                                            <button onClick={() => handleSubmitGitAccess()} className=" col-12 btn btn-sm btn-success fw-bold">
                                                Update
                                            </button>
                                        </Collapse>
                                    </div> :
                                    <div className="card border-secondary p-2">
                                        {data.user._id === projectData?.authorizedGitAccess?._id ? <div>
                                            You have git master access
                                            {projectData?.repoIntegration ? <div>
                                                Git Repo Integrated
                                            </div> : <div>
                                                Git Repo not integrated
                                                <button type="button" className="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                    Add Webhook URL
                                                </button>

                                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="staticBackdropLabel">Add Webhook URL</h5>
                                                                <button type="button" className="btn-close text-white rounded-pill fw-bold" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <h5>Step-by-Step Guide to Add Webhook URL</h5>
                                                                <ol>
                                                                    <li>
                                                                        <strong>Go to Your GitHub Repository:</strong> Navigate to the repository for which you want to set up the webhook.
                                                                    </li>
                                                                    <li>
                                                                        <strong>Open Settings:</strong> Click on the <strong>Settings</strong> tab located at the top of the repository page.
                                                                    </li>
                                                                    <li>
                                                                        <strong>Access Webhooks:</strong> In the left sidebar, click on <strong>Webhooks</strong>.
                                                                    </li>
                                                                    <li>
                                                                        <strong>Add a New Webhook:</strong> Click the <strong>Add webhook</strong> button.
                                                                    </li>
                                                                    <li>
                                                                        <strong>Enter Payload URL:</strong> In the <strong>Payload URL</strong> field, enter the URL: <code ref={payloadUrlRef}>{payloadUrl}</code>
                                                                        <FontAwesomeIcon
                                                                            icon={faClipboard}
                                                                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                                                                            onClick={() => copyToClipboard(payloadUrl)}
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <strong>Choose Content Type:</strong> Set the <strong>Content type</strong> to <code>application/json</code>.
                                                                    </li>
                                                                    <li>
                                                                        <strong>Select Events:</strong> Choose <strong>Send me everything</strong> to receive notifications for all events.
                                                                    </li>
                                                                    <li>
                                                                        <strong>Save Webhook:</strong> Click the <strong>Add webhook</strong> button at the bottom to save the webhook.
                                                                    </li>
                                                                    <li>
                                                                        <strong>Verify Integration:</strong> Go back to your devsemble's <strong>{projectData.projectTitle}</strong> Dashboard. You can see the integration status in the team overview section. You can also check the response from the ping request in <strong>Webhooks settings (Recent Delivery Tab)</strong> to ensure proper integration
                                                                    </li>
                                                                </ol>
                                                                <p>If you encounter any issues, please contact support for assistance.</p>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn fw-bold btn-danger" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`toast-container position-fixed top-0 end-0 p-3`} style={{ zIndex: 1055 }}>
                                                    <div className={`toast ${toastVisible ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true">
                                                        <div className="toast-header">
                                                            <strong className="me-auto">Clipboard</strong>
                                                            <button type="button" className="btn-close" onClick={() => setToastVisible(false)}></button>
                                                        </div>
                                                        <div className="toast-body">
                                                            Link payload copied to clipboard!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            <hr />
                                            {gitRepos.length === 0 && !projectData?.projectGitRepo ? <div>
                                                <button onClick={() => getAuthorizedUserRepos()} className="btn btn-sm btn-primary fw-bold col-12">
                                                    Get Repos
                                                </button></div> : null}
                                            {loading ? <div align="center" className="py-3"><CircularProgress size={30} /></div> : <>
                                                {gitRepos.length > 0 && !projectData?.projectGitRepo ? <div>
                                                    <div className="my-2 w-100">
                                                        <FormControl fullWidth>
                                                            <div className="">
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    placeholder="Type Repo Name"
                                                                    options={gitRepos.map(repo => ({ name: repo.name, link: repo.url }))}
                                                                    getOptionLabel={(option) => option.name || ''}
                                                                    value={selectedRepository}
                                                                    onChange={(event, newValue) => {
                                                                        setSelectedRepository(newValue === '' ? null : newValue);
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} label="Select Repository" />}
                                                                />
                                                                {/* Add error handling if needed */}
                                                                {!selectedRepository ? <div></div> : <div className="py-2">
                                                                    <button onClick={() => addGitRepo()} className="btn btn-sm btn-success fw-bold col-12">
                                                                        Select This Repo
                                                                    </button>
                                                                </div>}
                                                            </div>
                                                        </FormControl>
                                                    </div>
                                                </div> : <div className="fw-bold">
                                                    <div>Repo Name: <span><a target="_blank" style={{ textDecoration: 'none' }} href={`${projectData?.projectGitRepo?.link}`}>{projectData?.projectGitRepo?.name}</a></span></div>
                                                </div>}
                                            </>
                                            }
                                        </div> : <div>
                                            You don't have master access
                                        </div>}
                                    </div>}
                                <div className="fs-4 fw-bold card border-primary p-2 my-3">
                                    <span>Team Members: </span>
                                    {projectData?.projectTeam?.map((t, i) => (
                                        <div key={i} className="card bg-transparent border-0 py-1">
                                            <span className="fs-5 fw-bold">{t.firstName} {t.lastName}</span>
                                            <span className={!t.gitTokenId ? "fs-6 fw-bold text-danger" : "fs-6 fw-bold text-success"}>
                                                {!t.gitTokenId ? 'Git access not available' : 'Git access available'}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    {/* CHAT SECTION */}
                    <div className={`h-100 d-flex flex-wrap justify-content-end overflow-auto p-2 m-1 card border-primary bg-transparent rounded-lg ${isMdScreen ? 'col-md-4 col-12' : 'col-12'} ${!isTeamsVisible ? 'col-md-4' : ''}`}>
                        <div className="col-12">
                            <form className="container d-flex flex-column" onSubmit={sendMessage}>
                                <div id="divA" className="flex-grow-1" style={{ overflowY: 'scroll', height: `calc(70vh - 10px)`, scrollbarWidth: 'none' }}>
                                    <div className="py-2">
                                        {messages.map((msg, index) => (
                                            <div key={index} className="py-2">
                                                <div className={`text-justify`}>
                                                    <div className={`d-flex ${msg.sender === data.user.firstName ? `justify-content-end` : `justify-content-start`}`}>
                                                        <div style={{
                                                            backgroundColor: "#198CEF",
                                                            borderColor: "#198CEF"
                                                        }} className={`card shadow col-md-9 col-10 break-word border-2 rounded-3 ${msg.sender === data.user.firstName ? `text-white fw-bolder` : `fw-bolder bg-white border-light`}`}>
                                                            <div className={`px-2 pt-2 d-flex ${msg.sender === data.user.firstName ? `justify-content-end` : `justify-content-start`}`}>
                                                                <strong className="fw-bold">{msg.sender}</strong>
                                                            </div>
                                                            <hr className={`my-1 mx-2 ${msg.sender === data.user.firstName ? 'bg-white' : 'bg-dark'}`} />
                                                            <div className="p-2 fs-5">
                                                                {msg.content}
                                                            </div>
                                                            <div className="px-2">
                                                                <span className={`fw-bold d-flex ${msg.sender === data.user.firstName ? `justify-content-end text-light` : `justify-content-start text-muted `}`}>{formatTimestamp(msg.timestamp)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div ref={messagesEndRef} />
                                    </div>
                                </div>
                                <div id="divB" className="sticky-bottom">
                                    <div className="d-flex col-12 justify-content-evenly align-items-center">
                                        <div className="col-10">
                                            <CssTextField
                                                className='col-12 rounded-pill'
                                                id="custom-css-outlined-input"
                                                label="Send a message"
                                                autoComplete="false"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "50vh",
                                                    }
                                                }}
                                                variant="outlined" />
                                        </div>
                                        <div className="">
                                            <button type="submit" className="btn btn-primary rounded-circle">
                                                <FontAwesomeIcon size="2x" icon={faPaperPlane} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* TASK SECTIONS */}
                    <div className={`card border-primary bg-transparent rounded-lg ${isMdScreen ? 'col-md-5 col-12 m-1 h-100' : 'col-12'} ${!isTeamsVisible ? 'col-md-6' : 'p-2 my-1 '}`} style={{ overflowY: 'scroll' }}>
                        <Collapse in={activeCollapse === 'tasks'}>
                            {!isMdScreen ? <div className="d-md-none d-block">
                                {showMobileTask ?
                                    <button className="btn btn-sm col-12 btn-danger fw-bold" onClick={() => setShowMobileTasks(false)}>
                                        Close Tasks
                                    </button> :
                                    <button className="btn btn-sm col-12 btn-warning fw-bold" onClick={() => setShowMobileTasks(true)}>
                                        Show Tasks
                                    </button>
                                }
                            </div> : null}
                            <Collapse in={isMdScreen || showMobileTask} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                                <Collapse in={showAllTasks} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                                    <div>
                                        <div className="d-flex flex-wrap justify-content-around align-items-center">
                                            <button onClick={() => {
                                                setShowAllTasks(false)
                                                setShowMyTasks(true)
                                            }} className="btn fw-bold btn-warning rounded-pill">
                                                Go To My tasks
                                            </button>
                                            <h2>All tasks for project</h2>
                                        </div>
                                        <div>
                                            {projectTasks.length === 0 ? <div>No Tasks </div> : projectTasks?.map((pt, i) => (
                                                <div key={i}>
                                                    <div className={`my-3 rounded d-flex justify-content-between align-items-stretch flex-wrap`}>
                                                        <div className="col-md-10 col-12 d-flex ">
                                                            <div className={`col-12 card rounded-0 border-right border-2 ${pt.taskAccepted && !pt.taskStatus ? 'border-warning' :
                                                                pt.taskAccepted && pt.taskStatus ? 'border-success' : 'border-danger'
                                                                }`}>
                                                                <div className={`fw-bold d-flex justify-content-between p-2 `}>
                                                                    <h4 className="fw-bold m-0">{pt.taskTitle}</h4>
                                                                    <h5 className="fw-bold">To</h5>
                                                                    <h4 className="fw-bold m-0">{pt.assignedTo.firstName}</h4>
                                                                </div>
                                                                <div className="px-2">
                                                                    <span className="fs-4 fw-bold m-0">{pt.taskDescription}</span> <span className="px-2 fw-normal">{pt.taskStartDate} - {pt.taskEndDate}</span>
                                                                    {/* <p className="fs-5 m-0">Assigned To - {pt.assignedTo.firstName}</p> */}

                                                                    <h5 className="m-0">{pt.taskAccepted && !pt.taskStatus ? <span className="text-warning">Task Accepted</span> :
                                                                        pt.taskAccepted && pt.taskStatus ? <span className="text-success">Completed{pt?.modifyState === true ? <span className="px-2 text-danger">Changes Pending</span> : null}</span> : <span className="text-danger">Not Accepted</span>
                                                                    }</h5>
                                                                </div>
                                                                {data.user._id === projectData?.projectCoHost?._id ?
                                                                    <div className="p-2">
                                                                        <Collapse in={displayUpdatedData === -1} className="">
                                                                            <div className="d-flex justify-content-end">
                                                                                <button className="btn btn-sm fw-bold btn-primary" onClick={() => setDisplayUpdatedData(i)}>View Updates</button>
                                                                            </div>
                                                                        </Collapse>
                                                                        <Collapse in={displayUpdatedData === i} className="">
                                                                            <div className="d-flex justify-content-end">
                                                                                <button className="btn btn-sm fw-bold btn-danger" onClick={() => setDisplayUpdatedData(-1)}>Close Updates</button>
                                                                            </div>
                                                                            {/* {JSON.stringify(pt.taskUpdates)} */}
                                                                            <div className="py-3 p-2 card border-primary my-2">
                                                                                {pt?.taskUpdates.length === 0 ? <div className="text-center text-danger fw-bold">No Tasks</div> : pt?.taskUpdates.map((u, i) => (
                                                                                    <div className="" key={i}>
                                                                                        <div className="d-flex fs-5 flex-wrap justify-content-between align-items-center">
                                                                                            <div className="text-wrap col-12">
                                                                                                <div className="d-flex flex-wrap justify-content-between">
                                                                                                    <div className="fw-bold">
                                                                                                        Type: <span className="fw-normal">{u?.eventType} {u.isCompleted ? <span className="fw-normal">(completed)</span> : null}</span>
                                                                                                    </div>
                                                                                                    <div className="fw-bold">
                                                                                                        Author: <span className="fw-normal">{u?.author}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="fw-bold text-wrap">
                                                                                                    Message: <span className="fw-normal">{u?.messages}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr className="my-3" />
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </Collapse>
                                                                    </div> : null}
                                                            </div>
                                                        </div>
                                                        {data.user._id === projectData?.projectCoHost?._id ?
                                                            <div className="col-md-2 col-12 p-2 d-flex align-content-around flex-wrap">
                                                                <button onClick={() => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        tId: pt._id,
                                                                        taskTitle: pt.taskTitle,
                                                                        taskDescription: pt.taskDescription,
                                                                        assignedTo: pt.assignedTo._id,
                                                                        taskStatus: pt.taskStatus
                                                                    })
                                                                    setStartDateValue(pt.taskStartDate)
                                                                    setEndDateValue(pt.taskEndDate)
                                                                    setShowTaskForm(true)
                                                                    setShowAllTasks(false)
                                                                    setIsUpdate(true)
                                                                }} className="my-2 btn btn-sm btn-outline-warning col-12 rounded">
                                                                    <FontAwesomeIcon icon={faEdit} /><br />
                                                                    <span className=''>Update</span>
                                                                </button>
                                                                <button onClick={() => handleDeleteTask(pt._id)} className="my-2 btn btn-sm btn-outline-danger col-12 rounded">
                                                                    <FontAwesomeIcon icon={faTrash} /><br />
                                                                    <span className="">Delete</span>
                                                                </button>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Collapse>
                                <Collapse in={showMyTasks} style={{ overflowY: "scroll" }}>
                                    <div className="">
                                        <div className="py-2 d-flex flex-wrap justify-content-around align-items-center">
                                            <button onClick={() => {
                                                setShowAllTasks(true)
                                                setShowMyTasks(false)
                                            }} className="btn fw-bold btn-outline-primary rounded-pill">
                                                View All Tasks
                                            </button>
                                            <h2>Task Assigned To Me</h2>
                                            {data.user._id === projectData?.projectCoHost?._id ?
                                                <button onClick={() => {
                                                    setShowTaskForm(true)
                                                    setShowMyTasks(false)
                                                    setShowAllTasks(false)
                                                }} className="btn btn-primary rounded-pill">
                                                    <span className="px-2 fw-bold">Assign New Task</span>
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                                :
                                                null}
                                        </div>
                                        <div>
                                            {myTasks.length === 0 ? <div>No Tasks </div> : myTasks?.map((pt, i) => (
                                                <div key={i}>
                                                    <div className={`my-3 rounded d-flex justify-content-between align-items-stretch flex-wrap`}>
                                                        <div className="col-md-10 col-12 d-flex ">
                                                            <div className={`col-12 card rounded-0 border-right border-2 ${pt.taskAccepted && !pt.taskStatus ? 'border-warning' :
                                                                pt.taskAccepted && pt.taskStatus ? 'border-success' : 'border-danger'
                                                                }`}>
                                                                <div className={`fw-bold d-flex justify-content-between p-2 `}>
                                                                    <h4 className="fw-bold m-0">{pt.taskTitle}</h4>
                                                                    <h5 className="fw-bold">To</h5>
                                                                    <h4 className="fw-bold m-0">{pt.assignedTo.firstName}</h4>
                                                                </div>
                                                                <div className="px-2">
                                                                    <span className="fs-4 fw-bold m-0">{pt.taskDescription}</span> <span className="px-2 fw-normal">{pt.taskStartDate} - {pt.taskEndDate}</span>
                                                                    {/* <p className="fs-5 m-0">Assigned To - {pt.assignedTo.firstName}</p> */}

                                                                    <h5 className="m-0">{pt.taskAccepted && !pt.taskStatus ? <span className="text-warning">Task Accepted</span> :
                                                                        pt.taskAccepted && pt.taskStatus ? <span className="text-success">Completed{pt?.modifyState === true ? <span className="px-2 text-danger">Changes Pending</span> : null}</span> : <span className="text-danger">Not Accepted</span>
                                                                    }</h5>
                                                                </div>
                                                                <div className="p-2">
                                                                    <Collapse in={displayUpdatedData === -1} className="">
                                                                        <div className="d-flex justify-content-end">
                                                                            <button className="btn btn-sm fw-bold btn-primary" onClick={() => setDisplayUpdatedData(i)}>View Updates</button>
                                                                        </div>
                                                                    </Collapse>
                                                                    <Collapse in={displayUpdatedData === i} className="">
                                                                        <div className="d-flex justify-content-end">
                                                                            <button className="btn btn-sm fw-bold btn-danger" onClick={() => setDisplayUpdatedData(-1)}>Close Updates</button>
                                                                        </div>
                                                                        {/* {JSON.stringify(pt.taskUpdates)} */}
                                                                        <div className="py-3 p-2 card border-primary my-2">
                                                                            {pt?.taskUpdates.length === 0 ? <div className="text-center text-danger fw-bold">No Tasks</div> : pt?.taskUpdates.map((u, i) => (
                                                                                <div className="" key={i}>
                                                                                    <div className="d-flex fs-5 flex-wrap justify-content-between align-items-center">
                                                                                        <div className="text-wrap col-12">
                                                                                            <div className="d-flex flex-wrap justify-content-between">
                                                                                                <div className="fw-bold">
                                                                                                    Type: <span className="fw-normal">{u?.eventType} {u.isCompleted ? <span className="fw-normal">(completed)</span> : null}</span>
                                                                                                </div>
                                                                                                <div className="fw-bold">
                                                                                                    Author: <span className="fw-normal">{u?.author}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fw-bold text-wrap">
                                                                                                Message: <span className="fw-normal">{u?.messages}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <hr className="my-3" />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </Collapse>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`col-md-2 col-12 p-md-2 d-flex justify-content-between ${displayUpdatedData > 0 ? 'align-content-start' : 'align-content-around'} flex-wrap`}>
                                                            {pt.taskAccepted && !pt.taskStatus ?
                                                                <button onClick={() => {
                                                                    handleCompleteTask(pt._id)
                                                                }} className="my-2 btn btn-sm btn-outline-danger rounded">
                                                                    <FontAwesomeIcon icon={faEdit} /><br />
                                                                    <span className=''>Complete</span>
                                                                </button> : pt.taskAccepted && pt.taskStatus ? null :
                                                                    <button onClick={() => {
                                                                        handleAcceptTask(pt._id)
                                                                    }} className="my-2 btn btn-sm btn-outline-warning rounded">
                                                                        <FontAwesomeIcon icon={faCheckCircle} /><br />
                                                                        <span className=''>Accept</span>
                                                                    </button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Collapse>
                                {data.user._id === projectData?.projectCoHost?._id ?
                                    <Collapse in={showTaskForm}>
                                        <div className="py-2">
                                            <div className="card bg-tranparent border-0 p-2">
                                                {openModifiedForm === true ?
                                                    <div className="py-2 d-flex flex-wrap justify-content-around align-items-center">
                                                        <button onClick={() => {
                                                            setOpenModifiedForm(false)
                                                        }} className="btn btn-warning rounded-pill">
                                                            <FontAwesomeIcon icon={faArrowLeft} />
                                                            <span className="px-2 fw-bold">To Form</span>
                                                        </button>
                                                        <h2>Update Requirements</h2>
                                                    </div> :
                                                    <div className="py-2 d-flex flex-wrap justify-content-around align-items-center">
                                                        <button onClick={() => {
                                                            setShowTaskForm(false)
                                                            setShowMyTasks(true)
                                                            setShowAllTasks(false)
                                                            setIsUpdate(false)
                                                        }} className="btn btn-warning rounded-pill">
                                                            <FontAwesomeIcon icon={faArrowLeft} />
                                                            <span className="px-2 fw-bold">To tasks</span>
                                                        </button>
                                                        <h2>Assign New Task</h2>
                                                    </div>}
                                                <Collapse in={!openModifiedForm}>
                                                    <div className="col-12">
                                                        <div className=''>
                                                            <TextField
                                                                style={{ width: '100%' }}
                                                                className='my-2'
                                                                error={error === "formError" ? true : false}
                                                                id="outlined-basic"
                                                                value={taskTitle}
                                                                label={error === 'formError' ? errorMessage : "Task Title"}
                                                                onChange={handleChange('taskTitle')}
                                                                variant="outlined" />
                                                        </div>
                                                        <div className=''>
                                                            <TextField
                                                                style={{ width: '100%' }}
                                                                className='my-2'
                                                                error={error === "formError" ? true : false}
                                                                id="outlined-basic"
                                                                multiline
                                                                maxRows={4}
                                                                value={taskDescription}
                                                                label={error === 'formError' ? errorMessage : "Task Desciption"}
                                                                onChange={handleChange('taskDescription')}
                                                                variant="outlined" />
                                                        </div>
                                                        {error === 'dateError' ? <div className="text-danger text-center">{errorMessage}</div> : null}
                                                        <div className='d-flex flex-wrap justify-content-around'>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <MobileDatePicker
                                                                    label="Start Date"
                                                                    inputFormat="MM/DD/YYYY"
                                                                    // maxDate={new Date()}
                                                                    value={startDateValue}
                                                                    onChange={handleChangeStartDate}
                                                                    renderInput={(params) => <div className="py-2"><TextField {...params} /></div>}
                                                                />
                                                                <MobileDatePicker
                                                                    label="End Date"
                                                                    inputFormat="MM/DD/YYYY"
                                                                    minDate={startDateValue}
                                                                    // maxDate={new Date()}
                                                                    value={endDateValue}
                                                                    onChange={handleChangeEndDate}
                                                                    renderInput={(params) => <div className="py-2"><TextField  {...params} /></div>}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div>
                                                            {error === 'memberError' ? <div className="text-danger text-center">{errorMessage}</div> : null}
                                                            <label>Select a member to assign </label>
                                                            <StyledSelect
                                                                className="col-12"
                                                                id="demo-simple-select"
                                                                value={assignedTo}
                                                                // label="Select To assign"
                                                                variant="outlined"
                                                                onChange={handleChange('assignedTo')}
                                                            >
                                                                {projectData.projectTeam?.map((p, i) => (
                                                                    <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                                ))}
                                                            </StyledSelect>
                                                        </div>
                                                        <div className="py-3">
                                                            {isUpdate ?
                                                                <div className="d-flex flex-wrap justify-content-between aiign-items-center">
                                                                    <button onClick={() => handleUpdate({
                                                                        taskTitle,
                                                                        taskDescription,
                                                                        taskStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                                        taskEndDate: `${dayjs(endDateValue).format('MM/DD/YY')}`,
                                                                        assignedBy,
                                                                        assignedTo
                                                                    }, tId)} className="btn col-5 btn-warning">
                                                                        Update
                                                                    </button>
                                                                    <button onClick={() => setOpenModifiedForm(true)} className="btn col-5 btn-info">
                                                                        Need Upgrades
                                                                    </button></div> :
                                                                <button onClick={() => handleSubmit({
                                                                    projectId,
                                                                    taskTitle,
                                                                    taskDescription,
                                                                    taskStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                                    taskEndDate: `${dayjs(endDateValue).format('MM/DD/YY')}`,
                                                                    assignedBy,
                                                                    assignedTo
                                                                })} className="btn col-12 btn-outline-primary">
                                                                    Submit Task
                                                                </button>}
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                            <Collapse in={openModifiedForm}>
                                                <div className="col-12">
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2'
                                                            error={errorModified === "formError" ? true : false}
                                                            id="outlined-basic"
                                                            value={requirement}
                                                            label={errorModified === 'formError' ? errorMessageModified : "Mention New Requirement"}
                                                            onChange={handleModifiedChange('requirement')}
                                                            variant="outlined" />
                                                    </div>
                                                    <button onClick={() => handleUpgrade({
                                                        requirement,
                                                        updatedBy
                                                    }, tId)} className="btn col-12 btn-warning">
                                                        Push Upgrade
                                                    </button>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Collapse> : null}
                            </Collapse>
                        </Collapse>
                        <Collapse in={activeCollapse === 'editor'}>
                            <div>
                                <CollaborativeEditor />
                            </div>
                        </Collapse>
                        <Collapse in={activeCollapse === 'notes'} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                            <div>
                                <Notes projectTasks={projectTasks} teamMembers={projectData?.projectTeam} />
                            </div>
                        </Collapse>
                        <Collapse in={activeCollapse === 'vctool'} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                            <div>
                                <VoiceCall projectId={project_id} />
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div >
        </AuthNavbar >
    )
}

export default UserSingleProject